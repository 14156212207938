<div>
  <div class="wrapper">
    <div class="page-header page-header-xs" data-parallax="true"
      style="background-image: url('./assets/img/furkan-cover-photo.jpg');">
      <div class="filter"></div>
    </div>

    <div class="section-low-padding profile-content">
      <div class="container">
        <div class="owner">
          <div class="avatar" (mouseover)="pad=true" (mouseout)="pad=false" data-bs-toggle="modal"
            data-bs-target="#photoModal" placement="top" ngbTooltip="View Picture">
            <img *ngIf="!pad" [src]="PhotoFilePath" alt="Circle Image" class="img-circle img-no-padding img-responsive"
              height="150px;" width="200px;">
            <img *ngIf="pad" [src]="PhotoFilePath" alt="Circle Image" class="img-circle img-padding img-responsive"
              height="150px;" width="200px;">
          </div>
          <div class="name">
            <h4 class="title">{{currentUser.fullName}}</h4>
            <h6 class="description">{{currentUser.gender}} ID : {{currentUser.userId}}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="photoModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <img [src]="PhotoFilePath" alt="{{PhotoFilePath}}" class="img-rounded img-padding img-responsive">
          <button type="button" class="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 ml-auto mr-auto text-center">
      <p>Your profile is {{currentUser.status}}.</p>
      <p *ngIf="currentUser.status == 'Active' && !anyidAccepted">
        You cannot view your Top Matches' profile untill you request their info. You can view their profile after admin approval.</p>
      <p *ngIf="currentUser.status == 'Active' && !locked && anyidAccepted">Your profile is visible to anyone who has access to view your profile.
        If you are communicating with someone, you may 
        <a [routerLink]="['/user-profile',usertype,userid]" data-bs-toggle="modal" data-bs-target="#statusModal">Request Admin</a> 
        to temporarily LOCK your profile.
        You can change your mind later by requesting admin to unlock/Activate your profile again.</p>
      <p *ngIf="currentUser.status == 'Active' && locked && anyidAccepted">Your profile is visible to anyone who has access to view your profile.
        You have already requested admin to LOCK your profile with <a [routerLink]="['/view-profile',usertype, userid, lockedProfile[0].userId]" 
        target="_blank">{{lockedProfile[0].fullName}}</a>. If admin approves your request, your profile will not be visible to other ones.
        <a [routerLink]="['/user-profile',usertype,userid]" 
         (click)="cancelLockRequest()">Cancel LOCK Request</a>
      </p>
      <p *ngIf="currentUser.status == profileStatus[3] && lockedProfile[0].statusReq==7">Your profile is not visible to anyone except 
        <a [routerLink]="['/view-profile',usertype, userid, lockedProfile[0].userId]" target="_blank">
          {{lockedProfile[0].fullName}}</a>.
        If you feel you are not interested to communicate with this person anymore, you may 
        <a [routerLink]="['/user-profile',usertype,userid]" (click)="unlockRequest()">Request Admin</a> 
        to reject this ID and UNLOCK your profile.
        </p>
      <p *ngIf="currentUser.status == profileStatus[3] && lockedProfile[0].statusReq>7">Your profile is not visible to anyone except 
        <a [routerLink]="['/view-profile',usertype, userid, lockedProfile[0].userId]" target="_blank">
          {{lockedProfile[0].fullName}}</a>.
        Admin has received request to reject of this ID and UNLOCK your profile. Your profile will be unlocked when admin approves it.
        </p>
    </div>
  </div>
  <!-- For editing new instruction message, if anyone want to engage or not? -->
  <!-- <div class="row">
    <div class="col-md-6 ml-auto mr-auto text-center">
      <p>Your profile is {{currentUser.status}}.</p>
      <p *ngIf="currentUser.status == 'Active'">Your profile is visible to anyone who has access to view your profile.
        If you want to engage with your desired one, you may engage and lock your profile by the "ENGAGE?" button rigthside of the each id.
        <a [routerLink]="['/user-profile',usertype,userid]" data-bs-toggle="modal" data-bs-target="#statusModal">Request Admin</a> 
        to temporarily LOCK your profile.
        You can change your mind later by requesting admin to unlock/Activate your profile again.</p>
      <p *ngIf="currentUser.status == profileStatus[3]">Your profile is not visible to anyone except 
        <a [routerLink]="['/view-profile',usertype, userid, currentUser.lockedId]" target="_blank">
          {{getName(currentUser.lockedId)}}</a>.
        If you feel you are not interested to communicate with this person anymore, you may 
        <a [routerLink]="['/user-profile',usertype,userid]" data-bs-toggle="modal" data-bs-target="#unlockModal">Request Admin</a> 
        to UNLOCK your profile.
        </p>
    </div>
  </div> -->
  <br />

  <div class="modal fade" id="statusModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Request Admin to LOCK!</h5>
          <button type="button" class="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="d-flex flex-row bd-highlight">
            <div class="p-4 w-100 bd-highlight">

              <p>I would like to request to LOCK my ID with the following ID</p>
              <div class="form-group form-floating">
                <select [(ngModel)]="reqLock" class="form-select" id="reqLock">
                  <ng-container *ngFor="let id of acceptedIds">
                    <option *ngIf="id.status!=profileStatus[3]" value="{{id.matchingId}}">
                      {{id.userId}} - {{id.fullName}}
                    </option>
                  </ng-container>
                </select>
                <label for="reqLock">To Whom you want to LOCK with?</label>
              </div>
            </div>
          </div>

          <button type="button" class="btn btn-primary float-end" data-bs-dismiss="modal"
            (click)=lockRequest(reqLock)>OK</button>

        </div>

      </div>
    </div>
  </div>

  <div class="modal fade" id="engageModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Request Admin to Engage!</h5>
          <button type="button" class="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="d-flex flex-row bd-highlight">
            <div class="p-4 w-100 bd-highlight">

              <!-- <p>I would like to request to LOCK my ID with the following ID</p> -->
              <p>Surely, do you want to engage with this person? Do we lock your profile with this person?</p>
              <!-- <div class="form-group form-floating"> -->
                <!-- <select [(ngModel)]="reqLock" class="form-select" id="reqLock">
                  <ng-container *ngFor="let id of reqAccepted">
                    <option *ngIf=!isLocked(id) value="{{id}}">
                      {{id}} - {{getName(id)}}
                    </option>
                  </ng-container>
                </select> -->
                <!-- <label for="reqLock">To Whom you want to LOCK with?</label>
              </div> -->
            </div>
          </div>

          <button type="button" class="btn btn-primary float-end" data-bs-dismiss="modal"
            (click)=requestLock()>Yes, Sure!</button>

        </div>

      </div>
    </div>
  </div>

  <div class="modal fade" id="unlockModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Request Admin to LOCK!</h5>
          <button type="button" class="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="d-flex flex-row bd-highlight">
            <div class="p-4 w-100 bd-highlight">

              <p>{{unlockMsg}}</p>
            </div>
          </div>

          <button type="button" class="btn btn-primary float-end" data-bs-dismiss="modal"
            (click)=sendMessage(false)>OK</button>

        </div>

      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 ml-auto mr-auto text-center">
      <ng-container *ngIf="currentUser.status != 'Deactivated'"><h6 class="description" (click)="clickConsole()">Profile Completeness</h6>
      <ngb-progressbar type="success" class="mt-0" [value]=pct [striped]="true" [animated]="true"><i>{{pct}}%</i>
      </ngb-progressbar><br></ng-container>
      <div *ngIf="currentUser.status == 'Inactive'">
        <p>Your profile is not complete yet. To see who best matches you, please complete your profile.</p>
        <button [routerLink]="['/edit-profile',usertype,userid]" type="button"
          class="btn btn-outline-danger btn-round">Complete Profile</button>
      </div>
      <div *ngIf="currentUser.status == 'Pending'">
        <p class="important">Your profile is {{pct}}% complete. A request to activate your account is sent to admin. You will be able to
          see your best matches after admin approval. It may take 72 hours to 1 week to review your request.</p>
        <button [routerLink]="['/edit-profile',usertype,userid]" type="button"
          class="btn btn-outline-danger btn-round">Edit Profile</button>
      </div>
      <div *ngIf="currentUser.status == 'Deactivated'">
        <p>Your profile is Deactivated. Your profile is not visible to others. If you want to activate back your profile, please contact admin.</p>
        <button  *ngIf="currentUser.status != 'Deactivated'" [routerLink]="['/edit-profile',usertype,userid]" type="button"
          class="btn btn-outline-danger btn-round">Complete Profile</button>
      </div>
      <div>
        <p>Your profile is {{currentUser.status}}.</p>
      </div>
      <br>
      <div *ngIf=profileComplete()>
        <button type="button" class="btn btn-outline-danger btn-round">TOP MATCHES</button>
      </div>
    </div>

  </div>


  <div class="nav-tabs-navigation col-lg-10 col-md-6 ml-auto mr-auto" *ngIf=profileComplete()>
    <div class="nav-tabs-wrapper">
      <ngb-tabset [justify]="'center'">
        <ngb-tab title="Matches">
          <ng-template ngbTabContent>
            <div class="row following" id="follows">
              <div class="col-lg-9 col-md-6 ml-auto mr-auto">
                <ul class="list-unstyled follows">
                  <!--li>{{topMatcheUsers}}</li-->
                    <li *ngFor="let user of topMatches; let i=index">
                      <ng-container *ngIf="i<currentUser.matchShowLimit || user.statusReq==1 || user.statusReq==2">
                      <div class="row">
                        
                        <div class="avatar col-md-2 col-sm-2 ml-auto mr-auto">
                          <img src="{{PhotoUrl+user.photoUrl}}" alt="Circle Image"
                            class="img-circle img-no-padding img-responsive" height="70px;" width="70px;">
                        </div>
                        <div class="col-md-3 col-sm-4 ml-auto">
                          <h6>{{user.fullName}}<br /><small>Age {{user.age}}, {{user.highestEducation}}</small></h6>
                        </div>
                        <div class="col-md-3 col-sm-4 ml-auto">
                          <h6>{{user.percentage}}% Matching<br /><small>State: {{user.state}}</small></h6>
                        </div>
                        <div *ngIf="user.status!='Active'" class="col-md-4 col-sm-2 ml-auto mr-auto"
                          style="padding-top:15px;">
                          <div>
                            <label class="form-check-label">
                              <i *ngIf="user.status==profileStatus[3]" style="color:yellow;" class="fa fa-question-circle" aria-hidden="true" 
                              placement="top" ngbTooltip="This profile is in progress with some other Person. So you cannot view this profile at this moment. If the current progress is cancelled, the profile will be activated and you can view this profile."></i>
                              <i *ngIf="user.status==profileStatus[4]" style="color:yellow;" class="fa fa-question-circle" aria-hidden="true" 
                              placement="top" ngbTooltip="This profile is Deactivated from this site. Either this person found his/her partnar, or is not willing to continue profile in this site."></i>
                              <span class="form-check-sign">Profile {{user.status}}</span>
                            </label>
                          </div>
                        </div>
                        <div *ngIf="user.status=='Active'" class="col-md-4 col-sm-2 ml-auto mr-auto"
                          style="padding-top:15px;">
                          <button *ngIf="user.statusReq==0 && !isAdmin()"
                            type="button" class="btn btn-warning btn-sm" (click)=requestInfo(user)>Request
                            Info</button>
                          <div *ngIf="(user.statusReq==1 || user.statusReq==2) && !isAdmin()" class="form-check">
                            <label class="form-check-label">
                              <input class="form-check-input" type="checkbox" value="" checked="" disabled>
                              <span class="form-check-sign">Requested</span>
                            </label>
                          </div>
                          <div *ngIf="user.statusReq%10==4">
                            <label class="form-check-label">
                              <i style="color:red;" class="fa fa-exclamation-circle" aria-hidden="true"
                              placement="top" ngbTooltip="{{messageRejectionType(user.statusReq)}}"></i>
                              <span class="form-check-sign">Profile Rejected</span>
                            </label>
                          </div>

                          <a
                            [routerLink]="['/view-profile',genderMap(currentUser.gender),currentUser.userId,user.userId]"
                            target="_blank"><button *ngIf="(user.statusReq>=3 && user.statusReq%10!=4) && !isAdmin()" type="button"
                              class="btn btn-success btn-sm" (mouseover)=setXuser(user.userId)>View Profile</button></a>

                          <button *ngIf="(user.statusReq>=3 && user.statusReq%10!=4) && !isAdmin()" type="button" class="btn btn-danger btn-sm"
                                (click)=clickUserReject(user,i)>Reject</button>
                              
                          <button *ngIf="(user.statusReq==1 || user.statusReq==2) && isAdmin()" type="button" class="btn btn-success btn-sm"
                            (click)=giveAccess(user)>Give Access</button>
                          <button *ngIf="(user.statusReq==1 || user.statusReq==2) && isAdmin()" type="button" class="btn btn-danger btn-sm"
                            (click)=clickReject(user,i) data-bs-toggle="modal" data-bs-target="#rejectModal">Reject</button>
                          <div *ngIf="user.statusReq==3 && isAdmin()" class="form-check">
                            <label class="form-check-label">
                              <input class="form-check-input" type="checkbox" value="" checked="" disabled>
                              <span class="form-check-sign">Access Given</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </ng-container>
                    </li>
                </ul>
              </div>
            </div>
          </ng-template>
        </ngb-tab>

        <ngb-tab title="Requested">
          <ng-template ngbTabContent>
            <div class="row following" id="follows">
              <div class="col-lg-9 col-md-7 ml-auto mr-auto">
                <ul class="list-unstyled follows">
                  <!--li>{{topMatcheUsers}}</li-->
                    <li *ngFor="let user of topMatcheUsers; let i=index">
                      <ng-container *ngIf="user.statusReq>0">
                      <div class="row">
                        
                        <div class="avatar col-md-2 col-sm-2 ml-auto mr-auto">
                          <img src="{{PhotoUrl+user.photoUrl}}" alt="Circle Image"
                            class="img-circle img-no-padding img-responsive" height="70px;" width="70px;">
                        </div>
                        <div class="col-md-2 col-sm-4 ml-auto">
                          <h6>{{user.fullName}}<br /><small>Age {{user.age}}, {{user.highestEducation}}</small></h6>
                        </div>

                        
                        
                        <div class="col-md-2 col-sm-4 ml-auto">
                          <h6>{{user.percentage}}% Matching<br /><small>State: {{user.state}}</small></h6>
                        </div>
                        <div *ngIf="user.status!='Active'" class="col-md-4 col-sm-2 ml-auto mr-auto"
                          style="padding-top:15px;">
                          <div>
                            <label class="form-check-label">
                              <i *ngIf="user.status==profileStatus[3]" style="color:yellow;" class="fa fa-question-circle" aria-hidden="true" 
                              placement="top" ngbTooltip="This profile is in progress with some other Person. So you cannot view this profile at this moment. If the current progress is cancelled, the profile will be activated and you can view this profile."></i>
                              <i *ngIf="user.status==profileStatus[4]" style="color:yellow;" class="fa fa-question-circle" aria-hidden="true" 
                              placement="top" ngbTooltip="This profile is Deactivated from this site. Either this person found his/her partnar, or is not willing to continue profile in this site."></i>
                              <span class="form-check-sign">Profile {{user.status}}</span>
                            </label>
                          </div>
                        </div>
                        <div *ngIf="user.status=='Active'" class="col-md-4 col-sm-2 ml-auto mr-auto"
                          style="padding-top:15px;">
                          <button *ngIf="user.statusReq==0 && !isAdmin()"
                            type="button" class="btn btn-warning btn-sm" (click)=requestInfo(user)>Request
                            Info</button>
                          <div *ngIf="(user.statusReq==1 || user.statusReq==2) && !isAdmin()" class="form-check">
                            <label class="form-check-label">
                              <input class="form-check-input" type="checkbox" value="" checked="" disabled>
                              <span class="form-check-sign">Requested</span>
                            </label>
                          </div>
                          <div *ngIf="user.statusReq%10==4">
                            <label class="form-check-label">
                              <i style="color:red;" class="fa fa-exclamation-circle" aria-hidden="true"
                              placement="top" ngbTooltip="{{messageRejectionType(user.statusReq)}}"></i>
                              <span class="form-check-sign">Profile Rejected</span>
                            </label>
                          </div>

                          <a
                            [routerLink]="['/view-profile',genderMap(currentUser.gender),currentUser.userId,user.userId]"
                            target="_blank"><button *ngIf="(user.statusReq>=3 && user.statusReq%10!=4) && !isAdmin()" type="button"
                              class="btn btn-success btn-sm" (mouseover)=setXuser(user.userId)>View Profile</button></a>
                          <!-- <button *ngIf="(user.statusReq==3 && locked == false) && !isAdmin()" type="button" co class="btn btn-success btn-sm"
                            (click)=lockRequest(user)>Engage?</button> -->

                          <button *ngIf="(user.statusReq==1 || user.statusReq==2) && isAdmin()" type="button" class="btn btn-success btn-sm"
                            (click)=giveAccess(user)>Give Access</button>
                          <button *ngIf="(user.statusReq==1 || user.statusReq==2) && isAdmin()" type="button" class="btn btn-danger btn-sm"
                            (click)=clickReject(user,i) data-bs-toggle="modal" data-bs-target="#rejectModal">Reject</button>
                          <div *ngIf="user.statusReq==3 && isAdmin()" class="form-check">
                            <label class="form-check-label">
                              <input class="form-check-input" type="checkbox" value="" checked="" disabled>
                              <span class="form-check-sign">Access Given</span>
                            </label>
                          </div>
                        </div>

                        <!-- Engage button -->

                        

                        <!-- Engage Button -->
                        
                        
                      </div>
                    
                      <hr />
                    </ng-container>
                    </li>
                </ul>
              </div>
            </div>
          </ng-template>
        </ngb-tab>

      </ngb-tabset>
    </div>
  </div>

  <!-- Modal for Rejection Note
          =======-->
  <div class="modal fade" id="rejectModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Are you sure you want to reject?</h5>
          <button type="button" class="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="d-flex flex-row bd-highlight">
            <div class="p-4 w-100 bd-highlight">
              <!--div *ngIf="cuser.status!='Locked'" class="form-group form-floating">
                      <p>Are you Sure?</p>
                  </div-->
              <div class="form-group form-floating">
                <select [(ngModel)]="rejectNote" class="form-select" id="rejectNote">
                  <option *ngFor="let note of rejectionNote; let i=index" value={{i}}>
                    {{note}}
                  </option>
                </select>
                <label for="rejectNote">Why do you want to reject this?</label>
              </div>
            </div>
          </div>

          <button type="button" class="btn btn-primary float-end" data-bs-dismiss="modal">Cancel
          </button>
          <button type="button" class="btn btn-primary float-end" data-bs-dismiss="modal"
            (click)=rejectAccess(ruser,ri)>OK
          </button>
          
        </div>

      </div>
    </div>
  </div>
  <!-- ==== end Modal for Rejection Note -->

  <!--div class="modal fade" id="viewProfileModal" tabindex="-1"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl modal-dialog-centered">
    <div class="modal-content">
      <app-view-profile></app-view-profile>
    </div>
  </div>
</div-->
</div>