<div class="fixed-top" style="top: 60px;"  *ngIf=profilePercentage()>
  <ngb-progressbar type="warning" class="mt-0" [value]=pct [striped]="true" [animated]="true"><i>{{pct}}%</i></ngb-progressbar><br>
</div>
<!--style="top: 50px; left:200px; right:200px;"-->
<div class="wrapper">
    <div class="page-header page-header-xs" data-parallax="true" style="background-image: url('./assets/img/furkan-cover-photo.jpg');">
        <div class="filter"></div>
    </div>

    <div class=" section-low-padding profile-content">
        <div class="container">
            <div class="owner">
              <div class="avatar" (mouseover)="pad=true" (mouseout)="pad=false" data-bs-toggle="modal" data-bs-target="#photoModal"  placement="top" ngbTooltip="View/Change Picture">
                  <img *ngIf="!pad" [src]="PhotoFilePath" alt="Circle Image" class="img-circle img-no-padding img-responsive" height="150px;" width="200px;">
                  <img *ngIf="pad" [src]="PhotoFilePath" alt="Circle Image" class="img-circle img-padding img-responsive" height="150px;" width="200px;">
              </div>

                <div class="name">
                    <h4 class="title">{{currentUser.fullName}}</h4>
                    <h6 class="description">{{currentUser['gender']}} : Age {{currentUser.age}}</h6>
                </div>
            </div>
          </div>

</div>
</div>



<div class="modal fade" id="photoModal" tabindex="-1"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog modal-xl modal-dialog-centered">
<div class="modal-content">
    <div class="modal-header">
        <img [src]="PhotoFilePath" alt="{{PhotoFilePath}}" class="img-rounded img-padding img-responsive">
        <button type="button" class="btn-close float-end" data-bs-dismiss="modal"
        aria-label="Close"></button>
    </div>
    <div class="modal-body">
        <div class="ml-3 bd-highlight" style="width: 40%;">
          <input type="file" accept="image/*" (change)="uploadPhoto($event)" class="mt-2"/>
          <img *ngIf=uploading src="./assets/img/preloaders.gif">
          <p>Maximum Photo Size: {{maxSize}}</p>
        </div>
    </div>

</div>
</div>
</div>

<!-- Short Bio
==============--

<div class="row">
    <div class="col-md-6 ml-auto mr-auto">

              <div class="form-group">
                  <label>Short Bio</label>
                  <input type="text" class="form-control" placeholder="Enter Short Bio">
              </div>
          </div>
</div>
<end Short Bio -->

<!--==========================
  Our Portfolio Section
============================-->
<section id="portfolio" class="wow fadeInUp">

  <div class="container">
    <div class="section-header">
      <p>Please upload some of your photos that represent you well to your prospective partner. 
        <br>*You need to update your gallery and profile picture to complete your profile*
        <br>You must need to fill the RED BOXED field and ASTERIC (*) field to complete your profile.
      </p>
      </div>
  </div>
  <div class="container-fluid">
    <div class="row no-gutters">

      <div class="col-lg-3 col-md-4">
        <div class="portfolio-item wow fadeInUp">
          <a href="{{PhotoUrl+gallery[0]}}" class="portfolio-popup">
            <img src="{{PhotoUrl+gallery[0]}}" alt="" height="200px;">
            <div class="portfolio-overlay">
              <div class="portfolio-info"><h2 class="wow fadeInUp"></h2></div>
            </div>
          </a>
        </div>
      </div>

      <div class="col-lg-3 col-md-4">
        <div class="portfolio-item wow fadeInUp">
          <a href="{{PhotoUrl+gallery[1]}}" class="portfolio-popup">
            <img src="{{PhotoUrl+gallery[1]}}" alt="" height="200px;">
            <div class="portfolio-overlay">
              <div class="portfolio-info"><h2 class="wow fadeInUp"></h2></div>
            </div>
          </a>
        </div>
      </div>

      <div class="col-lg-3 col-md-4">
        <div class="portfolio-item wow fadeInUp">
          <a href="{{PhotoUrl+gallery[2]}}" class="portfolio-popup">
            <img src="{{PhotoUrl+gallery[2]}}" alt="" height="200px;">
            <div class="portfolio-overlay">
              <div class="portfolio-info"><h2 class="wow fadeInUp"></h2></div>
            </div>
          </a>
        </div>
      </div>

      <div class="col-lg-3 col-md-4">
        <div class="portfolio-item wow fadeInUp">
          <a href="{{PhotoUrl+gallery[3]}}" class="portfolio-popup">
            <img src="{{PhotoUrl+gallery[3]}}" alt="" height="200px;">
            <div class="portfolio-overlay">
              <div class="portfolio-info"><h2 class="wow fadeInUp"></h2></div>
            </div>
          </a>
        </div>
      </div>
      <button type="button" class="btn btn-danger"
      data-bs-toggle="modal" data-bs-target="#galleryModal"
      style="float: right;">Update Your Gallery</button>
    </div>

    <div class="modal fade" id="galleryModal" tabindex="-1"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Change Your Gallery Photos</h5>
            <button type="button" class="btn-close float-end"
            data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="d-flex flex-row bd-highlight">
              <div class="row">
                <div class="col-md-6" *ngFor="let photo of gallery; let i=index">
                  <img [src]="PhotoUrl+photo" class="img-rounded img-padding img-responsive"
                  style="width:100%; height:200px;">
                  <div class="row form-group files">
                      <label for="file-input">Change Photo</label>
                      <input type="file" title="image" id="file-input" accept="image/*"
                      (change)="uploadFile($event,i)" class="mt-2 col-md-8"/>
                  </div>
                </div>

              </div>
          </div>
        </div>

    </div>
    </div>
    </div>

  </div>
</section><!-- #portfolio -->


<div class="col-lg-9 content ml-auto mr-auto">
  <div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingOne">
      <button class="btn btn-danger accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
GENERAL INFORMATION
      </button>
    </h2>
    <div id="flush-collapseOne" class="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">

        <div class="row">
          <div class="col-sm-5">
            <div class="form-group form-floating">
                <input type="text" [(ngModel)]="currentUser.fullName" class="form-control" 
                [ngClass]="isFilled(currentUser.fullName) ? 'reqfilled' : 'required'"  
                placeholder="Enter Your Full Name" id="fullName" maxlength="{{max100}}">
                <label for="fullName">Full Name
                  <span class="errortext" *ngIf=!validateName(currentUser.fullName)>Spell your name correctly</span>
                </label>
            </div>
          </div>
            <div class="col-sm-3">
                <div class="form-group form-floating">
                    <input type="text" [(ngModel)]="currentUser.nickName" class="form-control"
                    placeholder="Enter Your Nickname(s)" id="nickname" maxlength="{{max100}}">
                    <label for="nickname">Nickname(s)</label>
                </div>
            </div>
            <div class="col-sm-4">
            <label>Gender</label>
            <div class="row">
              <div class="col-sm-4">
                <div class="form-check-radio">
                  <label class="form-check-label">
                    <input disabled [ngModel]="currentUser.gender" class="form-check-input" type="radio" name="gender" id="male" value="Male">
                    Male
                    <span class="form-check-sign"></span>
                  </label>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-check-radio">
                  <label class="form-check-label">
                    <input disabled [ngModel]="currentUser.gender" class="form-check-input" type="radio" name="gender" id="female" value="Female" >
                    Female
                    <span class="form-check-sign"></span>
                  </label>
                </div>
              </div>
            </div>
            </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="form-group form-floating">
                <textarea [(ngModel)]="currentUser.address" class="form-control" placeholder="Enter Your Full Address" 
                id="address" maxlength="{{max500}}"></textarea>
                <label for="address">Address</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-4">
            <div class="form-group form-floating">
                <input type="text" [(ngModel)]="currentUser.city" class="form-control" maxlength="{{max100}}"
                [ngClass]="isFilled(currentUser.city) ? 'reqfilled' : 'required'" placeholder="Enter City You Live in" id="city">
                <label for="city">City</label>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group form-floating" [ngClass]="{'input-group-focus':focus===true}">
                <select [(ngModel)]="currentUser.state" class="form-select" [ngClass]="isFilled(currentUser.state) ? 'reqfilled' : 'required'" id="state">
                  <option *ngFor="let state of STATES" value={{state.State}}>{{state.State}}</option>
                </select>
                <label for="state">State</label>
            </div>
          </div>
            <div class="col-sm-4">
                <div class="form-group form-floating">
                    <input type="text" [(ngModel)]="currentUser.zip" class="form-control" placeholder="Enter ZIP Code" id="zip">
                    <label for="zip">ZIP Code
                      <span class="errortext" *ngIf=!validateZIP(currentUser.zip)>Type ZIP code correctly</span>
                    </label>
                </div>
            </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            <div class="form-group form-floating">
                <input type="text" [(ngModel)]="currentUser.cellPhone" class="form-control" placeholder="Cell Phone Number" id="cellPhone"
                [ngClass]="isFilled(currentUser.cellPhone) ? 'reqfilled' : 'required'">
                <label for="cellPhone">Cell Phone
                  <span class="errortext" *ngIf=!telephoneCheck(currentUser.cellPhone)>Enter valid Phone No</span></label>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group form-floating">
                <input type="text" [(ngModel)]="currentUser.workPhone" class="form-control" placeholder="Work Phone" id="workPhone">
                <label for="workPhone">Work Phone
                  <span class="errortext" *ngIf=!telephoneCheck(currentUser.workPhone)>Enter valid Phone No</span>
                </label>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group form-floating">
                <input type="text" [(ngModel)]="currentUser.homePhone" class="form-control" placeholder="Home Phone" id="homePhone">
                <label for="homePhone">Home Phone
                  <span class="errortext" *ngIf=!telephoneCheck(currentUser.homePhone)>Enter valid Phone No</span>
                </label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="form-group form-floating">
                <input type="text" [(ngModel)]="currentUser.email" class="form-control" placeholder="Enter Your Email" id="email" disabled>
                <label for="email">Email</label>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group form-floating">
                <input type="text" [(ngModel)]="currentUser.personalWebsite" class="form-control" placeholder="Personal Website (if any)" id="personalWebsite">
                <label for="personalWebsite">Personal Website</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-3">
            <div class="form-group form-floating" [ngClass]="{'input-group-focus':focus===true}">
                <select (click)=getAge() [(ngModel)]="currentUser.birthYear" class="form-select" id="birthYear"
                [ngClass]="isFilled(currentUser.birthYear) ? 'reqfilled' : 'required'">
                  <option *ngFor="let year of YEARS" value={{year}}>{{year}}</option>
                </select>
                <label for="birthYear">Year of Birth</label>
            </div>
          </div>
          <!-- Date of Birth Omitted
          Instead Birth Year incorporated Below
          ================--
          <div class="col-sm-3">
            <div class="form-group form-floating" [ngClass]="{'input-group-focus':focus===true}">
                <input *ngIf=getAge() class="form-control" [(ngModel)]="currentUser.dateOfBirth"
                     name="dp" id="dateOfBirth" type="date" />
                <label for="dateOfBirth">Date of Birth</label>
            </div>
          </div>
          <!-- End date of birth
          =================-->
          <div class="col-sm-3">
            <div class="form-group form-floating">
                <input disabled type="text" [ngModel]="currentUser.age" class="form-control" placeholder="Enter Your Age (integer)" id="age">
                <label for="age">Age</label>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group form-floating">
                <input type="number" min="0" max="{{maxHeight}}" [(ngModel)]="currentUser.height" class="form-control"
                placeholder="Height" id="height" (keyup)="heightValidate()" [ngClass]="isFilled(currentUser.height) ? 'reqfilled' : 'required'">
                <label for="height">Height (inch)</label>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group form-floating">
                <input type="number" min="0" max="{{maxWeight}}" [(ngModel)]="currentUser.weight" class="form-control" 
                placeholder="Weight" id="weight"  (keyup)="weightValidate()" [ngClass]="isFilled(currentUser.weight) ? 'reqfilled' : 'required'">
                <label for="weight">Weight (lb)</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <p>Place of Birth (State, Province, District, Country)</p>
            <div class="form-group form-floating">
                <input type="text" [(ngModel)]="currentUser.placeOfBirth" class="form-control" placeholder="Place of Birth" 
                id="placeOfBirth" maxlength="{{max100}}">
                <label for="placeOfBirth">Place of Birth</label>
            </div>
          </div>
          <div class="col-sm-6">
            <p>Please disclose any health conditions, allergies, or disabilities, if any</p>
            <div class="form-group form-floating">
                <input type="text" [(ngModel)]="currentUser.health" class="form-control" 
                placeholder="health conditions" id="health" maxlength="{{max500}}">
                <label for="health">Health Conditions</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-3">
            <div class="form-group form-floating" [ngClass]="{'input-group-focus':focus===true}">
                <select [(ngModel)]="currentUser.maritalStatus" class="form-select" id="maritalStatus"
                [ngClass]="isFilled(currentUser.maritalStatus) ? 'reqfilled' : 'required'">
                  <option *ngFor="let status of MARSTATS  | slice:0:MARSTATS.length-1" value={{status.prop}}>{{status.prop}}</option>
                </select>
                <label for="maritalStatus">Current Marital Status</label>
            </div>
          </div>
            <div class="col-sm-3" *ngIf="isNotSingle()">
            <label>Do You Have Children?<span [ngClass]="isFilled(currentUser.children) ? 'filled' : 'req'">*</span></label>

            <div class="row">
                <div class="form-check-radio col-sm-3 radio-check-padded">
                  <label class="form-check-label" >
                    <input class="form-check-input" type="radio" [(ngModel)]="currentUser.children" name="children" id="no" value="No" checked="">
                    No
                    <span class="form-check-sign"></span>
                  </label>
                </div>
                <div class="form-check-radio col-sm-3 radio-check-padded">
                  <label class="form-check-label">
                    <input class="form-check-input" type="radio" [(ngModel)]="currentUser.children" name="children" id="yes" value="Yes" >
                    Yes
                    <span class="form-check-sign"></span>
                  </label>
                </div>
                </div>
              </div>

          <div *ngIf="isNotSingle() && hasChildren()" class="col-sm-3">
            <div class="form-group form-floating">
                <input type="number" min="1" max="{{maxChildrenNo}}" [(ngModel)]="currentUser.childrenNumber" class="form-control" placeholder="How many Children?" id="childrenNumber"
                [ngClass]="isFilled(currentUser.childrenNumber) ? 'reqfilled' : 'required'" (keyup)="childrenNoValidate()">
                <label for="childrenNumber">How many Children?</label>
            </div>
          </div>
            <div *ngIf="isNotSingle() && hasChildren()" class="col-sm-3">
                <div class="form-group form-floating">
                    <input type="text" [(ngModel)]="currentUser.childrenAges" class="form-control" 
                    placeholder="What are their Ages?" id="childrenAges" maxlength="{{max100}}">
                    <label for="childrenAges">What are their Ages?</label>
                </div>
                <p>Write their ages separated by comma (if more than one children)</p>
            </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="form-group form-floating" [ngClass]="{'input-group-focus':focus===true}">
                <select [(ngModel)]="currentUser.immigrationStatus" class="form-select" id="immigrationStatus"
                [ngClass]="isFilled(currentUser.immigrationStatus) ? 'reqfilled' : 'required'">
                  <option *ngFor="let status of IMSTATS  | slice:0:IMSTATS.length-1" value={{status.prop}}>{{status.prop}}</option>
                </select>
                <label for="immigrationStatus">Immigration Status</label>
            </div>
          </div>

            <div class="col-sm-6">
                <div class="form-group form-floating" *ngIf=imStatusOther()>
                    <input type="text" [(ngModel)]="currentUser.immigrationStatusOther" class="form-control" maxlength="{{max100}}"
                    placeholder="What are their Ages?" id="immigrationStatusOther">
                    <label for="immigrationStatusOther">If Other, please specify</label>
                </div>
            </div>
          </div>
      </div>
      </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingTwo">
      <button class="btn btn-danger accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
Academic & Professional Background
      </button>
    </h2>
    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
        <h6>Academic Background</h6>
      <div class="row">
        <div class="col-sm-4">
          <div class="form-group form-floating">
              <input type="text" [(ngModel)]="currentUser.highSchool" class="form-control" maxlength="{{max100}}"
              placeholder="High School" id="highSchool"
              [ngClass]="isFilled(currentUser.highSchool) ? 'reqfilled' : 'required'">
              <label for="highSchool">High School</label>
          </div>
        </div>
        <div class="col-sm-2">
            <div class="form-group form-floating">
                <select [(ngModel)]="currentUser.highSchoolYear" class="form-select" id="highSchoolYear">
                  <option *ngFor="let year of EDUYEARS" value={{year}}>{{year}}</option>
                </select>
                <label for="highSchoolYear">Year</label>
            </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group form-floating">
              <input type="text" [(ngModel)]="currentUser.bachelors" class="form-control" placeholder="Bachelors" id="bachelors"
              [ngClass]="isFilled(currentUser.bachelors) ? 'reqfilled' : 'required'" maxlength="{{max100}}">
              <label for="bachelors">Bachelors</label>
          </div>
        </div>
        <div class="col-sm-2">
            <div class="form-group form-floating">
                <select [(ngModel)]="currentUser.bachelorsYear" class="form-select" id="bachelorsYear">
                  <option *ngFor="let year of EDUYEARS" value={{year}}>{{year}}</option>
                </select>
                <label for="bachelorsYear">Year</label>
            </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <div class="form-group form-floating">
              <input type="text" [(ngModel)]="currentUser.masters" class="form-control" 
              maxlength="{{max100}}" placeholder="Masters in" id="masters">
              <label for="masters">Masters in</label>
          </div>
        </div>
        <div class="col-sm-2">
            <div class="form-group form-floating">
                <select [(ngModel)]="currentUser.mastersYear" class="form-select" id="mastersYear">
                  <option *ngFor="let year of EDUYEARS" value={{year}}>{{year}}</option>
                </select>
                <label for="mastersYear">Year</label>
            </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group form-floating">
              <input type="text" [(ngModel)]="currentUser.doctorate" class="form-control" 
              maxlength="{{max100}}" placeholder="Doctorate in" id="doctorate">
              <label for="doctorate">Doctorate in</label>
          </div>
        </div>
        <div class="col-sm-2">
            <div class="form-group form-floating">
                <select [(ngModel)]="currentUser.doctorateYear" class="form-select" id="doctorateYear">
                  <option *ngFor="let year of EDUYEARS" value={{year}}>{{year}}</option>
                </select>
                <label for="doctorateYear">Year</label>
            </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <div class="form-group form-floating">
              <input type="text" [(ngModel)]="currentUser.otherDegree" class="form-control" 
              maxlength="{{max100*2}}" placeholder="Other Degree(s)" id="otherDegree">
              <label for="otherDegree">Other Degree(s)</label>
          </div>
        </div>
        <div class="col-sm-2">
            <div class="form-group form-floating">
                <select [(ngModel)]="currentUser.otherDegreeYear" class="form-select" id="otherDegreeYear">
                  <option *ngFor="let year of EDUYEARS" value={{year}}>{{year}}</option>
                </select>
                <label for="otherDegreeYear">Year(s)</label>
            </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group form-floating" [ngClass]="{'input-group-focus':focus===true}">
              <select [(ngModel)]="currentUser.highestEducation"  class="form-select" id="highestEducation"
              [ngClass]="isFilled(currentUser.highestEducation) ? 'reqfilled' : 'required'">
                  <option *ngFor="let status of EDUCATION_LEVEL" value={{status}}>{{status}}</option>
              </select>
              <label for="highestEducation">Highest Education Level</label>
          </div>
        </div>
      </div>

        <h6>Professional Background</h6>
      <div class="row">
        <div class="col-sm-3">
        <label >Are you currently employed?<span [ngClass]="isFilled(currentUser.employed) ? 'filled' : 'req'">*</span></label>
        <br>
        <div class="row">
            <div class="form-check-radio col-sm-3 radio-check-padded">
              <label class="form-check-label">
                <input class="form-check-input" type="radio" [(ngModel)]="currentUser.employed" name="employed" id="yes" value="Yes" checked="">
                Yes
                <span class="form-check-sign"></span>
              </label>
            </div>
            <div class="form-check-radio col-sm-3 radio-check-padded">
              <label class="form-check-label">
                <input class="form-check-input" type="radio" [(ngModel)]="currentUser.employed" 
                name="employed" id="no" value="No" (click)="currentUser.income=1">
                No
                <span class="form-check-sign"></span>
              </label>
            </div>
            </div>
          </div>
        <div class="col-sm-3" *ngIf=isEmployed()>
          <div class="form-group form-floating">
              <input type="text" [(ngModel)]="currentUser.employment" class="form-control" maxlength="{{max100}}"
              placeholder="Where do you work?" id="employment">
              <label for="employment">Where do you work?</label>
          </div>
        </div>
        <div class="col-sm-3" *ngIf=isEmployed()>
            <div class="form-group form-floating">
                <input type="text" [(ngModel)]="currentUser.position" class="form-control" 
                maxlength="{{max100}}" placeholder="Position" id="position">
                <label for="position">Position</label>
            </div>
        </div>
        <div class="col-sm-3" *ngIf=isEmployed()>
          <div class="form-group form-floating">
              <input type="number" min="0" [(ngModel)]="currentUser.income" class="form-control" placeholder="Income (yearly)" id="income"
              (keyup)="incomeValidate()" [ngClass]="isFilled(currentUser.income) ? 'reqfilled' : 'required'">
              <label for="income">Yearly Income ($)</label>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingThree">
      <button class="btn btn-danger accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">

Parent/Guardian's Information
      </button>
    </h2>
    <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group form-floating">
                <input type="text" [(ngModel)]="currentUser.guarName" class="form-control" 
                maxlength="{{max100}}" placeholder="Names and Relationships" id="guarName"
                [ngClass]="isFilled(currentUser.guarName) ? 'reqfilled' : 'required'">
                <label for="guarName">Guardian Name
                  <span class="errortext" *ngIf=!validateName(currentUser.guarName)>Spell name correctly</span>
                </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group form-floating">
                <textarea [(ngModel)]="currentUser.guarAddress" class="form-control" maxlength="{{max500}}"
                placeholder="Guardian's Full Address" id="guarAddress"></textarea>
                <label for="guarAddress">Address</label>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group form-floating">
                <input type="text" [(ngModel)]="currentUser.guarCity" class="form-control" 
                maxlength="{{max100}}" placeholder="Guardian's City" id="guarCity">
                <label for="guarCity">City</label>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="form-group form-floating" [ngClass]="{'input-group-focus':focus===true}">
                <select [(ngModel)]="currentUser.guarState"  class="form-select" id="guarState">
                  <option *ngFor="let state of STATES" value={{state.State}}>{{state.State}}</option>
                </select>
                <label for="guarState">State</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            <div class="form-group form-floating">
                <input type="text" [(ngModel)]="currentUser.guarCountry" class="form-control" 
                maxlength="{{max100}}" placeholder="Guardian's Country" id="guarCountry">
                <label for="guarCountry">Country</label>
            </div>
          </div>
          <div class="col-sm-4">
              <div class="form-group form-floating">
                  <input type="text" [(ngModel)]="currentUser.guarPhone" class="form-control" placeholder="Guardian's Phone" id="guarPhone"
                  [ngClass]="isFilled(currentUser.guarPhone) ? 'reqfilled' : 'required'">
                  <label for="guarPhone">Phone
                    <span class="errortext" *ngIf=!telephoneCheck(currentUser.guarPhone)>Enter Valid Phone No</span>
                  </label>
              </div>
          </div>
          <div class="col-sm-4">
              <div class="form-group form-floating">
                  <input type="text" [(ngModel)]="currentUser.guarEmail" class="form-control" 
                  maxlength="{{max100}}" placeholder="Guardian's Email" id="guarEmail"
                  [ngClass]="isFilled(currentUser.guarEmail) ? 'reqfilled' : 'required'">
                  <label for="guarEmail">Email
                    <span class="errortext" *ngIf=!validateEmail(currentUser.guarEmail)>Enter Valid Email Address</span>
                  </label>
              </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
              <div class="form-group form-floating">
                  <input type="text" [(ngModel)]="currentUser.guarProfession" class="form-control" maxlength="{{max100}}"
                  placeholder="Guardian's Profession" id="guarProfession">
                  <label for="guarProfession">Profession</label>
              </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <p>Please enter any additional information about your family that you would like to share</p>
            <div class="form-group form-floating">
                <textarea [(ngModel)]="currentUser.familyBrief" class="form-control" maxlength="{{max500}}"
                placeholder="Family Brief" id="familyBrief"></textarea>
                <label for="familyBrief">Additional information</label>
            </div>
          </div>
        </div>
        </div>
      </div>
  </div>

  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingFour">
      <button class="btn btn-danger accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">

Personal Information
      </button>
    </h2>
    <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group form-floating" [ngClass]="{'input-group-focus':focus===true}">
                <select [(ngModel)]="currentUser.religiousPractice"  class="form-select" id="religiousPractice"
                [ngClass]="isFilled(currentUser.religiousPractice) ? 'reqfilled' : 'required'">
                    <option *ngFor="let status of RELIGIOUS_PRACTICE" value={{status}}>{{status}}</option>
                </select>
                <label for="religiousPractice">Personal Religious Practice</label>
            </div>
          </div>

          <div class="col-sm-6" >
            <div class="form-group form-floating" *ngIf=relPracticeOther()>
                <input type="text" [(ngModel)]="currentUser.religiousPracticeOther" class="form-control" 
                maxlength="{{max100}}" placeholder="if Other, Please Specify" id="religiousPracticeOther">
                <label for="religiousPracticeOther">if Other, Please Specify</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <p>Please write about your religious practices, in brief (ex: daily prayers, fasting, attending halaqas, visiting the masjid, etc.)</p>
            <div class="form-group form-floating">
                <textarea [(ngModel)]="currentUser.religiousPracticeBrief" class="form-control" maxlength="{{max500}}"
                placeholder="Religious Practice Brief" id="religiousPracticeBrief"></textarea>
                <label for="religiousPracticeBrief">Religious Brief</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
              <p>If you are a revert, please tell us, when did you accept Islam?</p>
              <div class="form-group form-floating">
                  <input type="text" [(ngModel)]="currentUser.revertTime" class="form-control" 
                  maxlength="{{max100}}" placeholder="Revert Time" id="revertTime">
                  <label for="revertTime">Revert Time</label>
              </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4" *ngIf=isMale()>
          <h6>Do You Have Beard?<span [ngClass]="isFilled(currentUser.beard) ? 'filled' : 'req'">*</span></h6>
              <div class="form-check-radio">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" [(ngModel)]="currentUser.beard" name="beard" id="bearded" value="Yes" checked="">
                  I have a beard
                  <span class="form-check-sign"></span>
                </label>
              </div>
              <div class="form-check-radio">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" [(ngModel)]="currentUser.beard" name="beard" id="noBeard" value="No" >
                  I don’t have a beard
                  <span class="form-check-sign"></span>
                </label>
              </div>
          </div>
          <div class="col-sm-12" *ngIf=!isMale()>

            <h6>I Wear -<span [ngClass]="isFilled(currentUser.wear) ? 'filled' : 'req'">*</span></h6>
            <div class="row">
                <div *ngFor="let status of WEARS" class="form-check col-sm-2 radio-check-padded" >
                  <label class="form-check-label">
                    <input [(ngModel)]="status.checked" class="form-check-input" type="checkbox" (click)=wearClick(status.id)>
                    {{status.prop}}
                    <span class="form-check-sign"  (mouseout)="setWear(true)"></span>
                  </label>
                </div>
            </div>
          </div>
          <div class="col-sm-3">
          <h6>Are you a smoker?<span [ngClass]="isFilled(currentUser.smoker) ? 'filled' : 'req'">*</span></h6>
              <div class="form-check-radio">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" [(ngModel)]="currentUser.smoker" name="smoker" id="no" value="No" checked="">
                  I do not smoke
                  <span class="form-check-sign"></span>
                </label>
              </div>
              <div class="form-check-radio">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" [(ngModel)]="currentUser.smoker" name="smoker" id="yes" value="Yes" >
                  I smoke
                  <span class="form-check-sign"></span>
                </label>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingFive">
      <button class="btn btn-danger accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">

SPOUSAL PREFERENCES
      </button>
    </h2>
    <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group form-floating" [ngClass]="{'input-group-focus':focus===true}">
                <select [(ngModel)]="currentUser.preReligious"  class="form-select" id="preReligious"
                [ngClass]="isFilled(currentUser.preReligious) ? 'reqfilled' : 'required'">
                    <option *ngFor="let status of RELIGIOUS_PRACTICE" value={{status}}>{{status}}</option>
                </select>
                <label for="preReligious">Preferred Religious Practices</label>
            </div>
          </div>

          <div class="col-sm-6" *ngIf=preReligiousOther()>
            <div class="form-group form-floating">
                <input type="text" [(ngModel)]="currentUser.preReligiousOther" class="form-control" maxlength="{{max100}}"
                placeholder="if Other, Please Specify" id="preReligiousOther">
                <label for="preReligiousOther">if Other, Please Specify</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <p>Please write any religious practice preferences, in brief</p>
            <div class="form-group form-floating">
                <textarea [(ngModel)]="currentUser.preReligiousBrief" class="form-control" maxlength="{{max500}}"
                placeholder="Religious Practice Preferences in Brief" id="preReligiousBrief"></textarea>
                <label for="preReligiousBrief">Religious Preference Brief</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12" *ngIf=isMale()>
            <h6>Preferences for a wife-<span [ngClass]="isFilled(currentUser.preWear) ? 'filled' : 'req'">*</span></h6>
            <div class="row">
                <div *ngFor="let status of WEARS" class="form-check col-sm-2 radio-check-padded" >
                  <label class="form-check-label">
                    <input [(ngModel)]="status.checked" class="form-check-input" type="checkbox" (click)=wearClick(status.id)>
                    {{status.prop}}
                    <span class="form-check-sign" (mouseout)="setWear(false)"></span>
                  </label>
                </div>
            </div>
          </div>

          <div class="col-sm-4" *ngIf=!isMale()>
          <h6>This question is only for Sisters</h6>
          <label>Preferences for a husband-<span [ngClass]="isFilled(currentUser.preBeard) ? 'filled' : 'req'">*</span></label>
          <br>
              <div class="form-check-radio">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" [(ngModel)]="currentUser.preBeard" name="preBeard" id="beard" value="Yes" checked="">
                  Beard
                  <span class="form-check-sign"></span>
                </label>
              </div>
              <div class="form-check-radio">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" [(ngModel)]="currentUser.preBeard" name="preBeard" id="noBeard" value="No" >
                  No Beard
                  <span class="form-check-sign"></span>
                </label>
              </div>
              <div class="form-check-radio">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" [(ngModel)]="currentUser.preBeard" name="preBeard" id="noPreference" value="No Preference" >
                  No Preference
                  <span class="form-check-sign"></span>
                </label>
              </div>
          </div>
          <div class="col-sm-12">
          <h6>Are You Fine with Smoking?<span [ngClass]="isFilled(currentUser.preSmoking) ? 'filled' : 'req'">*</span></h6>
          <div class="row">
              <div class="form-check-radio col-sm-2 radio-check-padded">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" [(ngModel)]="currentUser.preSmoking" name="preSmoking" id="no" value="No" checked="">
                  No
                  <span class="form-check-sign"></span>
                </label>
              </div>
              <div class="form-check-radio col-sm-2 radio-check-padded">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" [(ngModel)]="currentUser.preSmoking" name="preSmoking" id="yes" value="Yes" >
                  Yes
                  <span class="form-check-sign"></span>
                </label>
              </div>
              <div class="form-check-radio col-sm-6 radio-check-padded">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" [(ngModel)]="currentUser.preSmoking" name="preSmoking" id="noPreference" value="No Preference" >
                  No Preference
                  <span class="form-check-sign"></span>
                </label>
              </div>
              </div>
          </div>
          </div>
        <div class="row">
          <h6>Ethnic Background<span [ngClass]="isFilled(currentUser.preEthnic) ? 'filled' : 'req'">*</span></h6>
          <div class="col-sm-12">
              <div class="form-check-radio">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" [(ngModel)]="currentUser.preEthnic" name="preEthnic" id="own" value="Own Ethnicity" checked="">
                  I prefer my future spouse from my own ethnic background or country of origin
                  <span class="form-check-sign"></span>
                </label>
              </div>
            </div>
            <div class="col-sm-12" *ngIf=ownEthnicity()>
              <div class="form-group form-floating" [ngClass]="{'input-group-focus':focus===true}">
                  <select [(ngModel)]="currentUser.preEthnicSpecific"  class="form-select" id="preEthnicSpecific">
                      <option *ngFor="let status of ETHNIC_BACKGROUND" value={{status}}>{{status}}</option>
                  </select>
                  <label for="preEthnicSpecific">Specify ethnic background or country</label>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-check-radio">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" [(ngModel)]="currentUser.preEthnic" name="preEthnic" id="specific" value="Specific Ethnicity" >
                  I am seeking my future spouse to be from a specific ethnicity or country
                  <span class="form-check-sign"></span>
                </label>
              </div>
            </div>
            <div class="col-sm-12" *ngIf=specificEthnicity()>
              <div class="form-group form-floating" [ngClass]="{'input-group-focus':focus===true}">
                  <select [(ngModel)]="currentUser.preEthnicSpecific"  class="form-select" id="preEthnicSpecific"
                  [ngClass]="isFilled(currentUser.preEthnicSpecific) ? 'reqfilled' : 'required'">
                      <option *ngFor="let status of ETHNIC_BACKGROUND" value={{status}}>{{status}}</option>
                  </select>
                  <label for="preEthnicSpecific">Specify ethnic background or country</label>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-check-radio">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" [(ngModel)]="currentUser.preEthnic" name="preEthnic" id="noPreference" value="No Preference" >
                  I have no Preference
                  <span class="form-check-sign"></span>
                </label>
              </div>
            </div>
        </div>

        <h6>Immigration Status<span [ngClass]="isFilled(currentUser.preImmigrationStatus) ? 'filled' : 'req'">*</span></h6>
        <div class="row">
            <div class="col-sm-12">
          <label>I would prefer immigration status of my future spouse is-</label>
          <br>
          <div class="row">
              <div *ngFor="let status of IMSTATS; let id = index" class="form-check col-sm-4 radio-check-padded" >
                <label class="form-check-label">
                  <input [(ngModel)]="status.checked" class="form-check-input" type="checkbox" (click)=imstatClick(id)>
                  {{status.prop}}
                  <span class="form-check-sign" (mouseout)="setImstat()"></span>
                </label>
              </div>
          </div>

            </div>
          <div class="col-sm-5" *ngIf=false>
            <div class="form-group form-floating">
                <input type="text" [(ngModel)]="currentUser.preImmigrationStatusOther" class="form-control" 
                maxlength="{{max100}}" placeholder="if Other, Please Specify" id="preImmigrationStatusOther">
                <label for="preImmigrationStatusOther">if Other, Please Specify</label>
            </div>
          </div>
        </div>

        <div class="row">
        <h6>Marital Status<span [ngClass]="isFilled(currentUser.preMaritalStatus) ? 'filled' : 'req'">*</span></h6>

          <div class="col-sm-12">
          <label>I prefer a spouse who’s-</label>
          <br>
          <div class="row">
              <div *ngFor="let status of MARSTATS; let id = index" class="form-check col-sm-2 radio-check-padded" >
                <label class="form-check-label">
                  <input [(ngModel)]="status.checked" class="form-check-input" type="checkbox" (click)=marstatClick(id)>
                  {{status.prop}}
                  <span class="form-check-sign" (mouseout)="setMarstat()"></span>
                </label>
              </div>
          </div>


          </div>
          <h6>Children<span [ngClass]="isFilled(currentUser.preChildren) ? 'filled' : 'req'">*</span></h6>

            <div class="col-sm-12">
            <label>I am willing to consider a spouse with existing children-</label>
            <div class="row">
            <div class="form-check-radio col-sm-2 radio-check-padded">
              <label class="form-check-label">
                <input class="form-check-input" type="radio" [(ngModel)]="currentUser.preChildren" name="preChildren" id="yes" value="Yes">
                Yes
                <span class="form-check-sign"></span>
              </label>
            </div>
            <div class="form-check-radio col-sm-2 radio-check-padded">
              <label class="form-check-label">
                <input class="form-check-input" type="radio" [(ngModel)]="currentUser.preChildren" name="preChildren" id="no" value="No" >
                No
                <span class="form-check-sign"></span>
              </label>
            </div>
            </div>
            </div>

            <h6>Education<span [ngClass]="isFilled(currentUser.preEducation) ? 'filled' : 'req'">*</span></h6>

              <div class="col-sm-12">
              <label>Preferred Minimum Education Level of my Future Spouse-</label>
              <div class="row">
              <div *ngFor="let status of EDUCATION_LEVEL | slice:0:EDUCATION_LEVEL.length-1" class="form-check-radio col-sm-2 radio-check-padded">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" [(ngModel)]="currentUser.preEducation" name="preEducation" id="doctorate" value={{status}}>
                  {{status}}
                  <span class="form-check-sign"></span>
                </label>
              </div>
              <div class="form-check-radio col-sm-3 radio-check-padded">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" [(ngModel)]="currentUser.preEducation" name="preEducation" id="noPreference" value="No Preference">
                  No Preference
                  <span class="form-check-sign"></span>
                </label>
              </div>
              </div>
              </div>

              <h6>Employment<span [ngClass]="isFilled(currentUser.preEmployment) ? 'filled' : 'req'">*</span></h6>

                <div class="col-sm-12">
                <label>Preferred Employment Status of Future Spouse-</label>
                <div class="row">
                <div class="form-check-radio col-sm-3 radio-check-padded">
                  <label class="form-check-label">
                    <input class="form-check-input" type="radio" [(ngModel)]="currentUser.preEmployment" name="preEmployment" id="employed" value="Yes">
                    Employed
                    <span class="form-check-sign"></span>
                  </label>
                </div>

                <div class="form-check-radio col-sm-3 radio-check-padded">
                  <label class="form-check-label">
                    <input class="form-check-input" type="radio" [(ngModel)]="currentUser.preEmployment" 
                    name="preEmployment" id="notEmployed" value="No" (click)="currentUser.preIncome=1">
                    Not Employed
                    <span class="form-check-sign"></span>
                  </label>
                </div>
                <div class="form-check-radio col-sm-3 radio-check-padded">
                  <label class="form-check-label">
                    <input class="form-check-input" type="radio" [(ngModel)]="currentUser.preEmployment" 
                    name="preEmployment" id="noPreference" value="No Preference" (click)="currentUser.preIncome=1">
                    No Preference
                    <span class="form-check-sign"></span>
                  </label>
                </div>
                </div>
                </div>
                <div class="col-sm-6" *ngIf=preEmployed()>
                  <div class="form-group form-floating">
                      <input type="number" min="1" [(ngModel)]="currentUser.preIncome" class="form-control" 
                      placeholder="Preferred minimum yearly Income" id="preIncome" (keyup)="preIncomeValidate()"
                      [ngClass]="isFilled(currentUser.preIncome) ? 'reqfilled' : 'required'">
                      <label for="preIncome">Preferred minimum yearly Income of future spouse (USD)</label>
                  </div>
                </div>

                <h6>Age Difference</h6>

                  <div class="col-sm-12"></div>
                  <div class="col-sm-6">
                    <p>Preferred Maximum Age Difference of Future Spouse</p>
                    <div class="form-group form-floating">
                        <input type="number" min="0" max="{{maxAgeGap}}" step="1" [(ngModel)]="currentUser.preAgeGap"
                        class="form-control" placeholder="Maximum Age Difference" id="preAgeGap"  (keyup)="ageGapValidate()"
                        [ngClass]="isFilled(currentUser.preAgeGap) ? 'reqfilled' : 'required'">
                        <label for="preIncome">Maximum Age Difference</label>
                    </div>
                  </div>

        </div>
      </div>
    </div>
  </div>

  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingSix">
      <button class="btn btn-danger accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseTwo">
        Additional Information
      </button>
    </h2>
    <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
        <h6>Please mention at least 3 personal and/or professional references</h6>
      <div class="row">
        <div class="col-sm-4">
          <div class="form-group form-floating">
              <input type="text" [(ngModel)]="currentUser.refName1" class="form-control" placeholder="Name1" id="name1"
              [ngClass]="isFilled(currentUser.refName1) ? 'reqfilled' : 'required'" maxlength="{{max100}}">
              <label for="name1">Name
                <span class="errortext" *ngIf=!validateName(currentUser.refName1)>Spell name correctly</span>
              </label>
          </div>
        </div>
        <div class="col-sm-4">
            <div class="form-group form-floating">
                <input type="text" [(ngModel)]="currentUser.refRelation1" class="form-control" maxlength="{{max100}}"
                placeholder="Relationship1" id="relationship1"
                [ngClass]="isFilled(currentUser.refRelation1) ? 'reqfilled' : 'required'">
                <label for="relationship1">Relationship</label>
            </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group form-floating">
              <input type="text" [(ngModel)]="currentUser.refPhone1" class="form-control" placeholder="Phone1" id="phone1"
              [ngClass]="isFilled(currentUser.refPhone1) ? 'reqfilled' : 'required'">
              <label for="phone1">Phone
                <span class="errortext" *ngIf=!telephoneCheck(currentUser.refPhone1)>Enter Valid Phone No</span>
              </label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4">
          <div class="form-group form-floating">
              <input type="text" [(ngModel)]="currentUser.refName2" class="form-control" 
              maxlength="{{max100}}" placeholder="Name2" id="name2">
              <label for="name2">Name
                <span class="errortext" *ngIf=!validateName(currentUser.refName2)>Spell name correctly</span>
              </label>
          </div>
        </div>
        <div class="col-sm-4">
            <div class="form-group form-floating">
                <input type="text" [(ngModel)]="currentUser.refRelation2" class="form-control" 
                maxlength="{{max100}}" placeholder="Relationship2" id="relationship2">
                <label for="relationship2">Relationship</label>
            </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group form-floating">
              <input type="text" [(ngModel)]="currentUser.refPhone2" class="form-control" placeholder="Phone2" id="phone2">
              <label for="phone2">Phone
                <span class="errortext" *ngIf=!telephoneCheck(currentUser.refPhone2)>Enter Valid Phone No</span>
              </label>
          </div>
        </div>
      </div>
         <div class="row">
        <div class="col-sm-4">
          <div class="form-group form-floating">
              <input type="text" [(ngModel)]="currentUser.refName3" class="form-control" 
              maxlength="{{max100}}" placeholder="Name3" id="name3">
              <label for="name3">Name
                <span class="errortext" *ngIf=!validateName(currentUser.refName3)>Spell name correctly</span>
              </label>
          </div>
        </div>
        <div class="col-sm-4">
            <div class="form-group form-floating">
                <input type="text" [(ngModel)]="currentUser.refRelation3" class="form-control" 
                maxlength="{{max100}}" placeholder="Relationship3" id="relationship3">
                <label for="relationship3">Relationship</label>
            </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group form-floating">
              <input type="text" [(ngModel)]="currentUser.refPhone3" class="form-control" placeholder="Phone3" id="phone3">
              <label for="phone3">Phone
                <span class="errortext" *ngIf=!telephoneCheck(currentUser.refPhone3)>Enter Valid Phone No</span>
              </label>
          </div>
        </div>
      </div>
      <div class="row">
      <div class="col-sm-6">
      <h6> * Please upload a copy of your current, government-issued ID </h6>

        <label for="formFileMultiple" class="form-label">  </label>
        <a href="{{govIdPath}}" target="blank">View ID</a><br>
        <input class="form-control" type="file" accept=".pdf, image/*" id="formFileMultiple" (change)="uploadFile($event,11)" class="mt-2" />
        <img *ngIf=uploading src="./assets/img/preloaders.gif">
      </div>
      <div class="col-sm-6">
      <h6> * You may upload a copy of your current CV </h6>

        <label for="formFileMultiple" class="form-label">  </label>
        <a href="{{cvPath}}" target="blank">View CV</a><br>
        <input class="form-control" type="file" id="formFileMultiple" accept=".pdf, .doc, .docx" (change)="uploadFile($event,12)" class="mt-2" />
        <img *ngIf=uploading src="./assets/img/preloaders.gif">
      </div>
      </div>
      </div>
      </div>
      </div>





  </div>

  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingSeven">
      <button class="btn btn-danger accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSix">

Agreement
      </button>
    </h2>
    <div id="flush-collapseSeven" class="accordion-collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">

        <p>
        • I, <b>{{currentUser.fullName}}</b>, hereby certify that the information given in this form is true, correct, and complete in all respects. If there is any incorrect information in this form, I may be removed from the process.
        <br>
        • I will promptly inform MUNA Matrimonial Team of any changes in the information provided above. <br>
        • I authorize MUNA Matrimonial Team to utilize the information provided by me according to the needs of the service.<br>
        • I pledge to keep all the information provided by the MUNA Matrimonial Team confidential.<br>
        • If I get married at any time, I will inform the MUNA Matrimonial Team.<br>
        • As the MUNA Matrimonial Team is only introducing 2 parties to each other, in the event of a failure to arrange a marriage, I shall not hold MUNA Matrimonial Team or MUNA responsible. I agree to abide by decisions and rulings given by MUNA National President or his designated representatives in matters of disputes and will abide by decisions of MUNA, its National President, and/or their representatives.

      </p>
      <br><br>
      By submitting this form, I agree to a complete and thorough criminal and background check by MUNA Matrimonial Team.
      By signing this contract, I approve to a background check.

      <br> <br>
      Signature
      <div class="row">
          <div class="col-md">
            <div class="form-floating">
            <label class="form-label" for="customFile"></label>
              <img *ngIf="currentUser.signature!=null" [src]="signPath" alt="" 
              class="img-rounded img-padding img-responsive" height="50" width="150"><br>
              <input class="form-control" type="file" id="formFileMultiple" accept="image/*" (change)="uploadFile($event,13)" class="mt-2" />
              <img *ngIf=uploading src="./assets/img/preloaders.gif">
            </div>
          </div>

        <!--Date time
          ===================--
        <div class="col-md">
          <div class="form-floating">
            <input type="datetime-local" class="form-control" id="floatingInputGrid" placeholder="datetime-local value="datetime-local>
            <label for="floatingSelectGrid">Date</label>
          </div>
        </div>
        <!--End date time -->

      </div>

      </div>
      </div>
    </div>

  <div class="row" style="margin-top: 20px; margin-bottom: 20px;">
      <div class="col-md-9 ml-auto mr-auto" >
      </div>
      <div class="col-md-3">
          <button (click)=clickSave() class="btn btn-success btn-block btn-lg btn-fill">Save</button>
      </div>
  </div>
</div>
