<div class="wrapper">
    <div class="page-header" style="background-image: url('assets/img/intro-carousel/Picture1.jpg');">
        <div class="filter"></div>

            <div class="container">
                <div class="row center">
                    <div class="col-lg-4 mr-auto ml-auto">
                      <div class="card card-register">
                          <h3 *ngIf=(!isSignedUp()&&!gotVeriCode()) class="title">Welcome</h3>
                          <h6 *ngIf=isSignedUp() class="title">You Have Successfully Registered! Please Login to Continue.</h6>
                          <h6 *ngIf=gotVeriCode() class="title">Please enter the verification code sent to your email.</h6>

                          <!-- social text icon
                            =======================--
                          <div class="social-line text-center">
                              <a href="#pablo" class="btn btn-neutral btn-facebook btn-just-icon">
                                  <i class="fa fa-facebook-square"></i>
                              </a>
                              <a href="#pablo" class="btn btn-neutral btn-google btn-just-icon">
                                  <i class="fa fa-google-plus"></i>
                              </a>
                              <a href="#pablo" class="btn btn-neutral btn-twitter btn-just-icon">
                                  <i class="fa fa-twitter"></i>
                              </a>
                          </div> -->
                          <div class="register-form">
                            <div *ngIf=!gotVeriCode()>
                              <label>Email <span *ngIf="!emailValidate()" class="errortxt">Enter valid email address</span></label>
                              <div class="input-group form-group-no-border" [ngClass]="{'input-group-focus':focus===true}">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">
                                      <i class="nc-icon nc-email-85"></i>
                                  </span>
                                </div>
                                  <input autofocus type="text" [(ngModel)]="userEmail" (click)="refreshUserList()" class="form-control" placeholder="Email" 
                                  (keyup.enter)="enterPress()" (focus)="focus=true" (blur)="focus=false" >
                              </div>
                              <div *ngIf=!forgot()>
                              <label>Password</label>
                              <div class="input-group form-group-no-border" [ngClass]="{'input-group-focus':focus1===true}">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">
                                      <i class="nc-icon nc-key-25"></i>
                                  </span>
                                </div>
                                  <input type="password" [(ngModel)]="password" class="form-control" placeholder="Password" 
                                  (keyup.enter)="enterPress()" (focus)="focus1=true" (blur)="focus1=false" >
                              </div>
                              </div>



                              <button *ngIf=!forgot() (click)="clickLogin()" class="btn btn-danger btn-block btn-round">Login</button>
                              <button *ngIf=forgot() (click)="sendCode()" class="btn btn-danger btn-block btn-round">Confirm Email</button>
                              <p style="text-align:center; font-size:bold; color:red;">{{errorMessage}}</p>
                            </div>

                              <div *ngIf=gotVeriCode()>
                                <label>Enter Verification Code</label>
                                <div class="input-group form-group-no-border" [ngClass]="{'input-group-focus':focus===true}">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i *ngIf=!verifyEmail() class="nc-icon nc-alert-circle-i"></i>
                                        <i *ngIf=verifyEmail() style="color:green;" class="nc-icon nc-check-2"></i>
                                    </span>
                                  </div>

                                    <input type="text" (click)="refreshTempList()" class="form-control" [(ngModel)]="veriCode" 
                                    (keyup.enter)="enterPress()" placeholder="Six Digit Code" (focus)="focus=true" (blur)="focus=false" >
                                </div>
                                <button (click)="clickVerify()" class="btn btn-danger btn-block btn-round">Verify Email</button>
                                <p *ngIf=wrongCode style="color:red; text-align:center; font-size:bold;">Wrong Verification Code!</p>
                              </div>


                          </div>
                          <div *ngIf=!gotVeriCode() class="forgot">
                              <a *ngIf=!forgot() (click)=clickForgot() class="btn btn-link btn-danger">Forgot password?</a>
                              <a *ngIf=forgot() (click)=clickRemember() class="btn btn-link btn-danger">Remember Password? Login</a>
                              <a [routerLink]="['/signup']" class="btn btn-link">Haven't an Account? Sign Up!</a>
                          </div>
                      </div>
                    </div>
                </div>
                <div class="footer register-footer text-center">
                    <h6>&copy;{{test | date: 'yyyy'}}, made with <i class="fa fa-heart heart"></i> by Innovation21</h6>
                </div>
            </div>
    </div>
</div>
