<div class="col-lg-9 col-md-9 col-sm-12 ml-auto mr-auto">
    <h3 align="center">MUNA Matrimonial Privacy Policy</h3>
    <div class="d-flex flex-row bd-highlight">
        <div class="p-4 w-100 bd-highlight">
          <p *ngFor="let para of policy">{{para}}<br></p>
        </div>
    </div>
</div>


