<!--==========================
  About Section
============================-->
<section id="about" class="wow fadeInUp">
  <div>

    <div class="row">
      <!-- sidebar
      ======================-->
      <div class="col-lg-2">
        <button type="button" class="btn btn-success sidebar" (click)="refreshList()"><svg
            xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
            <path
              d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
          </svg> Refresh</button>
        <div *ngFor="let item of menu | slice:superadmin">
          <button type="button" class="btn btn-success sidebar" (click)=activeMenu(item.id)>{{item.name}}</button>
          <div *ngFor="let subitem of menu[item.id].submenu; let i=index">
            <button type="button" class="btn btn-success sidebar-sub" *ngIf=item.sub data-bs-toggle="modal"
              data-bs-target="#exampleModal" (click)="addClick(i)">{{subitem}}
            </button>
          </div>
        </div>
      </div>

      <!-- Content
      ======================-->
      <div class="col-lg-10 content">
        <!-- Admin Panel
        ======================-->
        <div *ngIf=menu[0].status>
          <table class="table table-striped">
            <thead>
              <tr>
                <th> Admin ID </th>
                <th> Admin User Name </th>
                <th> Admin Full Name</th>
                <th> Admin Level </th>
                <th> Admin Status</th>
                <th> Options </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let admin of adminusers">
                <td style="vertical-align: middle;"> {{admin.adminId}}</td>
                <td style="vertical-align: middle;">{{admin.adminUserName}} </td>
                <td style="vertical-align: middle;">{{admin.adminFullName}} </td>
                <td style="vertical-align: middle;">{{admin.adminLevel}} </td>
                <td style="vertical-align: middle;">{{admin.adminStatus}} </td>
                <td style="vertical-align: middle;">
                  <button type=button class="btn btn-light mr-1" data-bs-toggle="modal" data-bs-target="#exampleModal"
                    (click)="editClick(admin)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-pencil-square" viewBox="0 0 16 16">
                      <path
                        d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                    </svg>
                  </button>
                  <button type=button class="btn btn-light mr-1" (click)="deleteClick(admin.adminId)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-trash-fill" viewBox="0 0 16 16">
                      <path
                        d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">{{modalTitle}}</h5>
                  <button type="button" class="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div class="d-flex flex-row bd-highlight">
                    <div class="p-4 w-100 bd-highlight">
                      <div class="form-group form-floating">
                        <input type="text" class="form-control" [(ngModel)]="AdminUserName" id="AdminUserName">
                        <label for="AdminUserName">Admin User Name</label>
                      </div>

                      <div class="form-group form-floating">
                        <input type="text" class="form-control" [(ngModel)]="AdminPass" id="AdminPass">
                        <label for="AdminUserName">Admin Password</label>
                      </div>

                      <div class="form-group form-floating">
                        <input type="text" class="form-control" [(ngModel)]="AdminFullName" id="AdminFullName">
                        <label for="AdminUserName">Admin Full Name</label>
                      </div>

                      <div class="form-group form-floating">
                        <select [(ngModel)]="AdminLevel" class="form-select" id="AdminLevel">
                          <option *ngFor="let adminLevel of adminLevels" value={{adminLevel}}>{{adminLevel}}</option>
                        </select>
                        <label for="AdminLevel">Admin Level</label>
                      </div>
                      <div class="form-group form-floating">
                        <select [(ngModel)]="AdminStatus" class="form-select" id="AdminStatus">
                          <option *ngFor="let postStatus of postSTATUS" value={{postStatus}}>{{postStatus}}</option>
                        </select>
                        <label for="AdminStatus">Admin Status</label>
                      </div>

                    </div>
                  </div>

                  <button type="button" (click)="createClick()" *ngIf="AdminId==0" class="btn btn-primary float-end">
                    Create Admin
                  </button>

                  <button type="button" (click)="updateClick()" *ngIf="AdminId!=0" class="btn btn-primary float-end">
                    Update
                  </button>

                </div>

              </div>
            </div>
          </div>
        </div>
        <!-- ========= end Admin Panel -->

        <!-- Dashboard
        ======================-->
        <div *ngIf=menu[1].status class="ml-auto mr-auto">
          
          
    <!--==========================
      Our Team Section
    ============================-->
    <section>
      <div class="container">
        <div class="section-header">
          <h2>Welcome {{adminUser.adminFullName}}</h2>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <div>
              <span class="mycard-heading">Male Users</span>
              <div class="pic mycard-item" style="background-color: rgb(22, 28, 141); ">{{numOfMale}}</div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div>
              <span class="mycard-heading">Female Users</span>
              <div class="pic mycard-item" style="background-color: rgb(104, 3, 107); ">{{numOfFemale}}</div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div>
              <span class="mycard-heading">Active Users</span>
              <div class="pic mycard-item" style="background-color: rgb(21, 118, 11); ">{{numOfMaleActive+numOfFemaleActive}}</div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div>
              <span class="mycard-heading">Users In Progress</span>
              <div class="pic mycard-item" style="background-color: rgb(131, 44, 12); ">{{numOfMaleProgress+numOfFemaleProgress}}</div>
            </div>
          </div>

        </div>

      </div>
    </section><!-- #team -->
        
    <br><br>

    
        
          <table class="table table-striped">
            <thead>
              <tr>
                <th> ID </th>
                <th (click)="clickConsole()"> User Name </th>
                <th> Request Type</th>
                <th> Matching ID </th>
                <th> Options </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let user of maleusers.concat(femaleusers); let i = index">
                <ng-container *ngIf="anyRequest(user) && (user.status=='Active' || user.status=='Pending')">
                  <td style="vertical-align: middle;"> {{user.userId}}</td>
                  <td style="vertical-align: middle;">
                    <a [routerLink]="['/edit-profile',genderMap(user.gender),user.userId]" target="_blank"
                       (mouseover)="editUser(user, requestIndex(user)==2)" title="View Profile">
                      {{user.fullName}}</a>
                  </td>
                  <td style="vertical-align: middle;">{{requestType[requestIndex(user)]}} </td>
                  <td style="vertical-align: middle;">{{user.reqSent}} </td>
                  <td style="vertical-align: middle;" *ngIf="actionPerformed(i)==''">
                    <button type=button class="btn btn-outline-default btn-sm" data-bs-toggle="modal" data-bs-target="#emailModal" (click)="emailUser(user)"
                    title="Email User">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-at" viewBox="0 0 16 16">
                        <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z"/>
                        <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648Zm-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z"/>
                      </svg>
                    </button>
                    <button *ngIf="requestIndex(user)==0" type="button" class="btn btn-outline-default btn-sm"
                      (click)=profileActivate(user,i) title="Accept">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-square" viewBox="0 0 16 16">
                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                        <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                      </svg>
                    </button>
                    <button *ngIf="requestIndex(user)==0" type="button" class="btn btn-outline-default btn-sm"
                      (click)=clickReject(user,i,0) data-bs-toggle="modal" data-bs-target="#rejectModal" title="Reject">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                      </svg>
                    </button>
                    <button *ngIf="requestIndex(user)==2" type="button" class="btn btn-outline-default btn-sm"
                      (click)=changePP(user,i) title="Accept">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-square" viewBox="0 0 16 16">
                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                        <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                      </svg>
                    </button>
                    <button *ngIf="requestIndex(user)==2" type="button" class="btn btn-outline-default btn-sm"
                      (click)=clickReject(user,i,1) data-bs-toggle="modal" data-bs-target="#rejectModal" title="Reject">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                      </svg>
                    </button>
                    <a [routerLink]="['/user-profile',genderMap(user.gender),user.userId]" target="_blank"><button
                        *ngIf="requestIndex(user)==1" type="button" class="btn btn-outline-default btn-sm"
                        (mouseover)=editUser(user) title="View Requests">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                          <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                          <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                        </svg>
                      </button></a>
                  </td>
                  <td style="vertical-align: middle;">{{actionPerformed(i)}}</td>
                </ng-container>
              </tr>
              
              <!--==========================
                Request Sent by Male Section
              ============================-->

              <tr *ngFor="let request of maleRequest; let i = index">
                <ng-container *ngIf="request.status=='Active'">
                  <td style="vertical-align: middle;"> {{request.maleId}}</td>
                  <td style="vertical-align: middle;">
                    <a [routerLink]="['/edit-profile',1,request.maleId]" target="_blank"
                       title="View Profile">
                      {{request.fullName}}</a>
                  </td>
                  <td style="vertical-align: middle;">{{requestType[1]}} </td>
                  <td style="vertical-align: middle;">
                    <a [routerLink]="['/edit-profile',2,request.femaleId]" target="_blank"
                       title="View Profile">{{request.femaleName}}</a> </td>
                  <td style="vertical-align: middle;" *ngIf="actionPerformed(i)==''">
                    <button type=button class="btn btn-outline-default btn-sm" data-bs-toggle="modal" data-bs-target="#emailModal" (click)="emailUser(request)"
                    title="Email User">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-at" viewBox="0 0 16 16">
                        <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z"/>
                        <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648Zm-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z"/>
                      </svg>
                    </button>
                    
                    
                    <a [routerLink]="['/user-profile',1,request.maleId]" target="_blank"><button
                        type="button" class="btn btn-outline-default btn-sm"
                         title="View Requests">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                          <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                          <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                        </svg>
                      </button></a>
                  </td>
                  <td style="vertical-align: middle;">{{actionPerformed(i)}}</td>
                </ng-container>
              </tr>

              <!-- Lock Request by Male Section Start-->

              <tr *ngFor="let request of maleRequestLock; let i = index">
                <ng-container>
                  <td style="vertical-align: middle;"> {{request.maleId}}</td>
                  <td style="vertical-align: middle;">
                    <a [routerLink]="['/edit-profile',1,request.maleId]" target="_blank"
                       title="View Profile">
                      {{request.fullName}}</a>
                  </td>
                  <td *ngIf="request.statusReq==5 || request.statusReq==11 || request.statusReq==13" 
                    style="vertical-align: middle;">Request for LOCK</td>
                  <td *ngIf="request.statusReq==8 || request.statusReq==12 || request.statusReq==14" 
                    style="vertical-align: middle;">Request for UNLOCK</td>
                  <td style="vertical-align: middle;">
                    <a [routerLink]="['/edit-profile',2,request.femaleId]" target="_blank"
                       title="View Profile">{{request.femaleName}}</a></td>
                  <td style="vertical-align: middle;" *ngIf="request.statusReq==5 || request.statusReq==8">
                    <button type=button class="btn btn-outline-default btn-sm" (click)=acceptLockRequest(request,i,1)
                    title="Accept">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-square" viewBox="0 0 16 16">
                      <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                      <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                    </svg>
                    </button>
                    
                    
                    <button
                        type="button" class="btn btn-outline-default btn-sm" (click)=rejectLockRequest(request,i,1)
                         title="Reject">
                         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
                          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                      </button>
                  </td>
                  <td *ngIf="request.statusReq==11 || request.statusReq==12" style="vertical-align: middle;">Accepted</td>
                  <td *ngIf="request.statusReq==13 || request.statusReq==14" style="vertical-align: middle;">Rejected</td>
                </ng-container>
              </tr>



              <!-- Lock Request by Male Section End-->

              <!--==========================
                Request Sent by Female Section
              ============================-->

              <tr *ngFor="let request of femaleRequest; let i = index">
                <ng-container *ngIf="request.status=='Active'">
                  <td style="vertical-align: middle;"> {{request.femaleId}}</td>
                  <td style="vertical-align: middle;">
                    <a [routerLink]="['/edit-profile',2,request.femaleId]" target="_blank"
                      title="View Profile">
                      {{request.fullName}}</a>
                  </td>
                  <td style="vertical-align: middle;">{{requestType[1]}} </td>
                  <td style="vertical-align: middle;">
                    <a [routerLink]="['/edit-profile',1,request.maleId]" target="_blank"
                      title="View Profile">{{request.maleName}}</a> </td>
                  <td style="vertical-align: middle;" *ngIf="actionPerformed(i)==''">
                    <button type=button class="btn btn-outline-default btn-sm" data-bs-toggle="modal" data-bs-target="#emailModal" (click)="emailUser(request)"
                     title="Email User">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-at" viewBox="0 0 16 16">
                        <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z"/>
                        <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648Zm-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z"/>
                      </svg>
                    </button>
                    
                    
                    <a [routerLink]="['/user-profile',2,request.femaleId]" target="_blank"><button
                        type="button" class="btn btn-outline-default btn-sm"
                         title="View Requests">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                          <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                          <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                        </svg>
                      </button></a>
                  </td>
                  <td style="vertical-align: middle;">{{actionPerformed(i)}}</td>
                </ng-container>
              </tr>

              <!-- Lock Requests By Female Start-->


              <tr *ngFor="let request of femaleRequestLock; let i = index">
                <ng-container>
                  <td style="vertical-align: middle;"> {{request.femaleId}}</td>
                  <td style="vertical-align: middle;">
                    <a [routerLink]="['/edit-profile',2,request.femaleId]" target="_blank"
                      title="View Profile">
                      {{request.fullName}}</a>
                  </td>
                  <td *ngIf="request.statusReq==6 || request.statusReq==11 || request.statusReq==13" style="vertical-align: middle;">Request for LOCK</td>
                  <td *ngIf="request.statusReq==9 || request.statusReq==12 || request.statusReq==14" style="vertical-align: middle;">Request for UNLOCK</td>
                  <td style="vertical-align: middle;">
                    <a [routerLink]="['/edit-profile',1,request.maleId]" target="_blank"
                    title="View Profile">{{request.maleName}}</a> </td>
                  <td style="vertical-align: middle;" *ngIf="request.statusReq==6 || request.statusReq==9">
                     <button type=button class="btn btn-outline-default btn-sm" (click)=acceptLockRequest(request,i,2)
                     title="Accept">
                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-square" viewBox="0 0 16 16">
                       <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                       <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                     </svg>
                    </button>
                    
                    
                    <button
                        type="button" class="btn btn-outline-default btn-sm" (click)=rejectLockRequest(request,i,2)
                         title="Reject">
                         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
                          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                      </button>
                  </td>
                  <td *ngIf="request.statusReq==11 || request.statusReq==12" style="vertical-align: middle;">Accepted</td>
                  <td *ngIf="request.statusReq==13 || request.statusReq==14" style="vertical-align: middle;">Rejected</td>
                </ng-container>
              </tr>




              <!-- Lock Requests By Female End-->



            </tbody>
          </table>
          <!-- Modal for Run Matching
          =======-->
          <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">{{matchingVar}}</h5>
                  <button type="button" class="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                  <button type="button" class="btn btn-primary float-end" data-bs-dismiss="modal">
                    OK
                  </button>

                </div>

              </div>
            </div>
          </div>
          <!-- ==== end Modal for Run Matching -->

          <!-- Modal for Rejection Note
          =======-->
          <div class="modal fade" id="rejectModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Are you sure you want to reject?</h5>
                  <button type="button" class="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div class="d-flex flex-row bd-highlight">
                    <div class="p-4 w-100 bd-highlight">
                      <!--div *ngIf="cuser.status!='Locked'" class="form-group form-floating">
                      <p>Are you Sure?</p>
                  </div-->
                      <div class="form-group form-floating">
                        <select [(ngModel)]="rejectNote" class="form-select" id="rejectNote">
                          <option *ngFor="let note of rejectionNote; let i=index" value={{i}}>
                              {{note}}
                          </option>
                        </select>
                        <label for="rejectNote">Why do you want to reject this?</label>
                      </div>
                    </div>
                  </div>

                  <button type="button" class="btn btn-primary float-end" data-bs-dismiss="modal"
                    >Cancel
                  </button>
                  <button type="button" class="btn btn-primary float-end" data-bs-dismiss="modal"
                    *ngIf="rtype==0" (click)=profileReject(ruser,ri)>OK
                  </button>
                  <button type="button" class="btn btn-primary float-end" data-bs-dismiss="modal"
                    *ngIf="rtype==1" (click)=rejectPP(ruser,ri)>OK
                  </button>
                  

                </div>

              </div>
            </div>
          </div>
          <!-- ==== end Modal for Rejection Note -->

          <!--div class="modal fade" id="viewModaledit" tabindex="-1"
              aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-centered">
              <div class="modal-content">
                <app-edit-profile></app-edit-profile>
              </div>
            </div>
          </div-->
          <!--div class="modal fade" id="viewModal" tabindex="-1"
              aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-centered">
              <div class="modal-content">
                <app-profile></app-profile>
              </div>
            </div>
          </div-->
        </div>
        <!-- ========= end Dashboard -->

        <!-- Male and Female User
        ======================-->
        <div *ngIf="menu[2].status||menu[3].status">
          <div class="row">
          <div class="col-sm-3 col-md-6 ml-auto">
            <ngb-pagination [collectionSize]="(menu[2].status? maleusersFiltered.length : femaleusersFiltered.length)" [(page)]="page" aria-label="Default pagination"></ngb-pagination>
          </div>
          <div class="col-sm-3 mr-auto">
            <div class="input-group" [ngClass]="{'input-group-focus':focus===true}">
                <input type="text" [(ngModel)]="userFilter" class="form-control" placeholder="Search by Name" aria-describedby="basic-addon1"
                (focus)="focus=true" (blur)="focus=false" (keyup)="userFilterFn()" >
                <div class="input-group-append">
                  <span class="input-group-text" id="basic-addon1"><i class="fa fa-search" aria-hidden="true"></i></span>
                </div>
            </div>
          </div>
          </div>
          <br>
          (Click on Table Header button to Sort Ascending or Descending) {{extra}}
          <table class="table table-striped">
            <thead>
              <tr>
                <th (click)="sortResult('userId',sortasc)" style="cursor:pointer;">ID
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z"/></svg>
                </th>
                <th (click)="sortResult('fullName',sortasc)" style="cursor:pointer;">Name 
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z"/></svg>
                </th>
                <th (click)="sortResult('email',sortasc)" style="cursor:pointer;">Email
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z"/></svg>
                </th>
                <th (click)="sortResult('cellPhone',sortasc)" style="cursor:pointer;">Phone
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z"/></svg>
                 </th>
                <th>Match Limit</th>
                <th (click)="sortResult('status',sortasc)" style="cursor:pointer;">Status
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z"/></svg>
                </th>
                <th (click)="sortResult('lastEdit',sortasc)" style="cursor:pointer;">Last Activity
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z"/></svg>
                </th>
                <th>Options</th>
              </tr>
            </thead>
            <tbody *ngIf="menu[2].status">
              <!--tr *ngFor="let user of (menu[2].status ? maleusers : femaleusers)"><ng-container> -->
              <tr *ngFor="let user of maleusersFiltered; let i=index">
                <ng-container *ngIf="i>=(page-1)*itemPerPage && i<page*itemPerPage">
                  <td style="vertical-align: middle;">{{user.userId}}</td>
                  <td style="vertical-align: middle;">
                    <a [routerLink]="['/user-profile',genderMap(user.gender),user.userId]" target="_blank" title="View Matches">
                      {{user.fullName}}</a></td>
                  <td style="vertical-align: middle; cursor:pointer; color:blueviolet" data-bs-toggle="modal" data-bs-target="#emailModal" (click)="emailUser(user)"
                  title="Email User">{{user.email.substring(0, user.email.indexOf('@'))+'\n'+user.email.substring(user.email.indexOf('@'))}}</td>
                  <td style="vertical-align: middle;">{{user.cellPhone}}</td>
                  <td style="vertical-align: middle;">
                    <div class="form-group" style="padding-top:15px;">
                      <select (change)=updateUser(user) [(ngModel)]="user.matchShowLimit" class="form-select" id="state">
                        <option *ngFor="let limit of topMatchLimits" value={{limit}}>{{limit}}</option>
                      </select>
                    </div>
                  </td>
                  <td style="vertical-align: middle;" (click)="getCurrentStatus(user)">
                    <div class="form-group" style="padding-top:15px;" placement="bottom"
                      ngbTooltip="{{user.lockedId}}{{(user.lockedId==null? '' : ' '+getName(user.lockedId.toString(),user.gender))}}">
                      <select (change)=updateUser(user) [(ngModel)]="user.status" class="form-select" id="state">
                        <option *ngFor="let status of profileStatus" value={{status}}>
                          {{status}}
                        </option>
                      </select>
                    </div>
                  </td>
                  <td style="vertical-align: middle;">
                    {{user.lastEdit.substring(0,10)+' '+user.lastEdit.substring(11,19)}}
                  </td>
                  <td style="vertical-align: middle;">
                    <button type=button class="btn btn-light mr-1" data-bs-toggle="modal" data-bs-target="#recommendModal" (click)="cuser=user" title="Recommend Soulmate">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-hearts" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M11.5 1.246c.832-.855 2.913.642 0 2.566-2.913-1.924-.832-3.421 0-2.566ZM9 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h10s1 0 1-1-1-4-6-4-6 3-6 4Zm13.5-8.09c1.387-1.425 4.855 1.07 0 4.277-4.854-3.207-1.387-5.702 0-4.276ZM15 2.165c.555-.57 1.942.428 0 1.711-1.942-1.283-.555-2.281 0-1.71Z"/>
                      </svg>
                    </button>
                    
                    
                    <a [routerLink]="['/edit-profile','1',user.userId]" target="_blank"> <button type=button
                        class="btn btn-light mr-1" (mouseover)=editUser(user) title="Edit Profile">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-pencil-square" viewBox="0 0 16 16">
                          <path
                            d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                          <path fill-rule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                        </svg>
                      </button></a>
                    <button type=button class="btn btn-light mr-1" (click)="deleteUser(user)" title="Delete USer">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-trash-fill" viewBox="0 0 16 16">
                        <path
                          d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                      </svg>
                    </button>
                  </td>
                </ng-container>
              </tr>
            </tbody>
            <tbody *ngIf="menu[3].status">
              <tr *ngFor="let user of femaleusersFiltered; let i=index">
                <ng-container *ngIf="i>=(page-1)*itemPerPage && i<page*itemPerPage">
                  <td style="vertical-align: middle;">{{user.userId}}</td>
                  <td style="vertical-align: middle;">
                    <a [routerLink]="['/user-profile',genderMap(user.gender),user.userId]" target="_blank" title="View Matches">
                    {{user.fullName}}</a></td>
                  <td style="vertical-align: middle; cursor:pointer; color:blueviolet" data-bs-toggle="modal" data-bs-target="#emailModal" (click)="emailUser(user)"
                    title="Email User">{{user.email.substring(0, user.email.indexOf('@'))+'\n'+user.email.substring(user.email.indexOf('@'))}}</td>
                  <td style="vertical-align: middle;">{{user.cellPhone}}</td>
                  <td style="vertical-align: middle;">
                    <div class="form-group" style="padding-top:15px;">
                      <select (change)=updateUser(user) [(ngModel)]="user.matchShowLimit" class="form-select"
                        id="state">
                        <option *ngFor="let limit of topMatchLimits" value={{limit}}>{{limit}}</option>
                      </select>
                    </div>
                  </td>
                  <td style="vertical-align: middle;" (click)="getCurrentStatus(user)">
                    <div class="form-group" style="padding-top:15px;" placement="bottom"
                      ngbTooltip="{{user.lockedId}}{{(user.lockedId==null? '' : ' '+getName(user.lockedId.toString(),user.gender))}}">
                      <select (change)="updateUser(user)" [(ngModel)]="user.status" class="form-select" id="state">
                        <option *ngFor="let status of profileStatus" value={{status}}>
                          {{status}}
                        </option>
                      </select>
                    </div>
                  </td>
                  <td style="vertical-align: middle;">
                    {{user.lastEdit.substring(0,10)+' '+user.lastEdit.substring(11,19)}}
                  </td>
                  <td style="vertical-align: middle;">
                    <button type=button class="btn btn-light mr-1" data-bs-toggle="modal" data-bs-target="#recommendModal" (click)="cuser=user" title="Recommend Soulmate">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-hearts" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M11.5 1.246c.832-.855 2.913.642 0 2.566-2.913-1.924-.832-3.421 0-2.566ZM9 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h10s1 0 1-1-1-4-6-4-6 3-6 4Zm13.5-8.09c1.387-1.425 4.855 1.07 0 4.277-4.854-3.207-1.387-5.702 0-4.276ZM15 2.165c.555-.57 1.942.428 0 1.711-1.942-1.283-.555-2.281 0-1.71Z"/>
                      </svg>
                    </button>
                    <a [routerLink]="['/edit-profile','2',user.userId]" target="_blank"><button type=button
                        class="btn btn-light mr-1" (mouseover)=editUser(user) title="Edit Profile">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-pencil-square" viewBox="0 0 16 16">
                          <path
                            d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                          <path fill-rule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                        </svg>
                      </button></a>
                    <button type=button class="btn btn-light mr-1" (click)="deleteUser(user)" title="Delete User">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-trash-fill" viewBox="0 0 16 16">
                        <path
                          d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                      </svg>
                    </button>
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>

          <div class="modal fade" id="statusModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Profile {{cuser.status}}!</h5>
                  <button type="button" class="btn-close float-end" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div class="d-flex flex-row bd-highlight">
                    <div class="p-4 w-100 bd-highlight">
                      <!--div *ngIf="cuser.status!='Locked'" class="form-group form-floating">
                      <p>Are you Sure?</p>
                  </div-->
                      <div class="form-group form-floating">
                        <select [(ngModel)]="lockid" class="form-select" id="lockedId">
                          <ng-container *ngFor="let match of topMatches">
                            <option *ngIf=!isLocked(match.userId,cuser.gender) value={{match.userId}}>
                              {{match.userId}} - {{match.fullName}}
                            </option>
                          </ng-container>
                        </select>
                        <label for="lockedId">To Whom {{cuser.fullName}} is {{profileStatus[3]}}?</label>
                      </div>
                    </div>
                  </div>

                  <button type="button" class="btn btn-primary float-end" data-dismiss="modal"
                    (click)=updateUser(cuser,0)>OK</button>

                </div>

              </div>
            </div>
          </div>

          <div class="modal fade" id="recommendModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Recommendation for {{cuser.fullName}}</h5>
                  <button type="button" class="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div class="d-flex flex-row bd-highlight">
                    <div class="p-4 w-100 bd-highlight">
        
                      <p>Type recommended User ID</p>
                      <div class="form-group form-floating">
                        <input type="number" min="1" [(ngModel)]="recuserid" class="form-control" placeholder="Recommended User ID"
                          id="recuserid">
                        <label for="recuserid">Cell Phone</label>
                      </div>
                      <p>{{getName(recuserid,cuser.gender)}}</p>
                    </div>
                  </div>
        
                  <button type="button" class="btn btn-primary float-end" data-bs-dismiss="modal"
                    (click)=recommend(cuser,recuserid)>OK</button>
        
                </div>
        
              </div>
            </div>
          </div>

          <!--div class="modal fade" id="editModal" tabindex="-1"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
          <app-edit-profile></app-edit-profile>
        </div>
        </div>
        </div-->

          <!-- Create User Modal
        ======================-->
          <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Add User</h5>
                  <button type="button" class="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div class="d-flex flex-row bd-highlight">
                    <div class="p-4 w-100 bd-highlight">
                      <div class="form-group form-floating">
                        <input type="text" [(ngModel)]="FullName" class="form-control"
                          placeholder="Enter Your Full Name" id="fullName">
                        <label for="FullName">Full Name</label>
                      </div>

                      <div class="form-group form-floating">
                        <input type="text" [(ngModel)]="CellPhone" class="form-control" placeholder="Cell Phone Number"
                          id="cellPhone">
                        <label for="CellPhone">Cell Phone</label>
                      </div>

                      <div class="form-group form-floating">
                        <input type="text" [(ngModel)]="Email" class="form-control" placeholder="Enter Your Email"
                          id="email">
                        <label for="Email">Email</label>
                      </div>

                      <div class="form-group form-floating" [ngClass]="{'input-group-focus':focus===true}">
                        <input class="form-control" [(ngModel)]="DateOfBirth" name="dp" id="dateOfBirth" type="date" />
                        <label for="DateOfBirth">Date of Birth</label>
                      </div>
                    </div>
                  </div>

                  <button type="button" (click)="createUser()" class="btn btn-primary float-end">
                    Create User
                  </button>

                </div>


              </div>
            </div>
          </div>
          <!-- ========= end Create User Modal -->



        </div>

        <!-- ========= end Male User -->

        <!-- Matching Criteria
        ======================-->
        <div *ngIf=menu[4].status>
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Matching ID</th>
                <th>Matching Details</th>
                <th>Point For Male</th>
                <th>Point For Female</th>
                <th>Options</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let matching of matchingtables">
                <td> {{matching.matchingId}} </td>
                <td> {{matching.matchingDetails}} </td>
                <td> {{matching.malePoint}} </td>
                <td> {{matching.femalePoint}} </td>
                <td>
                  <button type=button class="btn btn-light mr-1" data-bs-toggle="modal"
                    data-bs-target="#matchingTableModal" (click)="editMatchingClick(matching)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-pencil-square" viewBox="0 0 16 16">
                      <path
                        d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="modal fade" id="matchingTableModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Edit Matching Point</h5>
                  <button type="button" class="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div class="d-flex flex-row bd-highlight">
                    <div class="p-4 w-100 bd-highlight">
                      <div class="form-group form-floating">
                        <input type="text" class="form-control" [(ngModel)]="MatchingDetails" id="MatchingDetails">
                        <label for="MatchingDetails">Matching Details</label>
                      </div>

                      <div class="form-group form-floating">
                        <input type="text" class="form-control" [(ngModel)]="MalePoint" id="MalePoint">
                        <label for="MalePoint">Male Point</label>
                      </div>

                      <div class="form-group form-floating">
                        <input type="text" class="form-control" [(ngModel)]="FemalePoint" id="FemalePoint">
                        <label for="FemalePoint">Female Point</label>
                      </div>
                    </div>
                  </div>

                  <button type="button" (click)="updateMatchingClick()" class="btn btn-primary float-end">
                    Update
                  </button>

                </div>

              </div>
            </div>
          </div>
        </div>
        <!-- ========= end Matching Criteria -->

        <!-- Message
        ======================-->
        <div *ngIf=menu[5].status>

          <table class="table table-striped">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Message</th>
                <th>Reply</th>
                <th>Options</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let message of messages">
                <td> {{message.senderId}} </td>
                <td> {{message.senderEmail}} </td>
                <td> {{message.messageDetail.substring(0,30)}}
                  <ng-container *ngIf="message.messageDetail.length>30">...</ng-container>
                  <br>{{message.dateTime}}
                </td>
                <td *ngIf="message.messageReply!=null">
                  {{message.messageReply.substring(0,30)}}
                  <ng-container *ngIf="message.messageReply.length>30">...</ng-container>
                  <br>{{message.replyDateTime}}
                </td>
                <td *ngIf="message.messageReply==null">No reply yet.</td>
                <td>
                  <button type=button class="btn btn-light mr-1" data-bs-toggle="modal" data-bs-target="#messageModal"
                    (click)="replyMessage(message)">
                    <svg *ngIf="message.messageReply==null" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                      fill="currentColor" class="bi bi-reply" viewBox="0 0 16 16">
                      <path
                        d="M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z" />
                    </svg>
                    <svg *ngIf="message.messageReply!=null" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                      fill="currentColor" class="bi bi-shield-fill-check" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                        d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0 0-1.044 1.262c-.596 4.477.787 7.795 2.465 9.99a11.777 11.777 0 0 0 2.517 2.453c.386.273.744.482 1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7.159 7.159 0 0 0 1.048-.625 11.775 11.775 0 0 0 2.517-2.453c1.678-2.195 3.061-5.513 2.465-9.99a1.541 1.541 0 0 0-1.044-1.263 62.467 62.467 0 0 0-2.887-.87C9.843.266 8.69 0 8 0zm2.146 5.146a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647z" />
                    </svg>
                  </button>
                  <button type=button class="btn btn-light mr-1" (click)="deleteMessage(message)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-trash-fill" viewBox="0 0 16 16">
                      <path
                        d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>


          <div class="modal fade" id="messageModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">{{SenderName}}</h5>
                  <button type="button" class="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div class="d-flex flex-row bd-highlight">
                    <div class="p-4 w-100 bd-highlight">
                      <div class="form-group form-floating">
                        <table>
                          <tr>
                            <td>{{currentMessage.dateTime}}</td>
                          </tr>
                          <tr>
                            <td><strong><p *ngFor="let msg of currentMessage.messageDetail.split('\n')">{{msg}}</p>
                              </strong></td>
                          </tr>
                        </table>
                        <br>
                        <table *ngIf="currentMessage.messageReply!=null">
                          <tr>
                            <td>Replied: {{currentMessage.replyDateTime}}</td>
                          </tr>
                          <tr>
                            <td><strong>{{currentMessage.messageReply}}</strong></td>
                          </tr>
                        </table>
                      </div>

                      <div *ngIf="currentMessage.messageReply==null" class="form-group form-floating">
                        <textarea class="form-control" style="min-height: 10em;
                          overflow: auto;" [(ngModel)]="MessageReply" id="reply" contentEditable></textarea>
                        <label for="reply">Write a reply</label>
                      </div>

                    </div>
                  </div>

                  <button *ngIf="currentMessage.messageReply==null" type="button" (click)="sendReply()"
                    class="btn btn-primary float-end">
                    Send Reply
                  </button>

                </div>

              </div>
            </div>
          </div>
        </div>
        <!-- ========= end Message -->

        <!-- Site Management
        ======================-->
        <div *ngIf=menu[6].status>
          <div class="row">
            <div class="form-group form-floating col-lg-3">
              <select class="form-select" id="PostType" [(ngModel)]="PostTypeFilter" style="width:200px;"
                (change)="FilterFn()" placeholder="Filter">
                <option value="">All Types</option>
                <option *ngFor="let postType of postTypes" value={{postType.name}}>{{postType.name}}</option>
              </select>
              <label for="PostType">Search by Post Type</label>
            </div>
            <div *ngIf=false class="form-group form-floating col-lg-3">
              <select class="form-select" id="pageTitle" [(ngModel)]="PageTitleFilter" style="width:200px;"
                (change)="FilterFn()" placeholder="Filter">
                <option value="">All Pages</option>
                <option *ngFor="let pageTitle of pageTitles" value={{pageTitle}}>{{pageTitle}}</option>
              </select>
              <label for="pageTitle">Search by Page Title</label>
            </div>
          </div>
          <table class="table table-striped">

            <thead>
              <tr>
                <th>Code</th>
                <th>Page</th>
                <th>PostType</th>
                <th>Details</th>
                <th>Photo</th>
                <th>Status</th>
                <th>Options</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let post of allpost">
                <td> {{post.postCode}} </td>
                <td> {{post.pageTitle}} </td>
                <td> {{post.postType}} </td>
                <ng-container *ngIf="post.param1==null">
                  <td></td>
                </ng-container>
                <ng-container *ngIf="post.param1!=null">
                  <td *ngIf="post.param1.length<=maxPostDetailLen"> {{post.param1}}</td>
                  <td *ngIf="post.param1.length>maxPostDetailLen"> {{post.param1.substring(0,maxPostDetailLen)}}
                    <button type="button" class="btn btn-link" (click)=viewMore(post.param1) data-bs-toggle="modal"
                      data-bs-target="#viewMoreModal" style="padding-left:0px; padding-right:0px;">....</button>
                  </td>
                </ng-container>
                <ng-container *ngIf="post.postPhoto==null">
                  <td></td>
                </ng-container>
                <ng-container *ngIf="post.postPhoto!=null">
                  <td *ngIf="post.postPhoto.length<=maxPhotoNameLen"> {{post.postPhoto}} </td>
                  <td *ngIf="post.postPhoto.length>maxPhotoNameLen"> {{post.postPhoto.substring(0,maxPhotoNameLen)}}
                  </td>
                </ng-container>
                <td> {{post.postStatus}} </td>
                <td>
                  <button type=button class="btn btn-light mr-1" data-bs-toggle="modal" data-bs-target="#exampleModal"
                    (click)="editClick(post)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-pencil-square" viewBox="0 0 16 16">
                      <path
                        d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                    </svg>
                  </button>
                </td>
                <td>
                  <button type=button class="btn btn-light mr-1" (click)="deletePost(post)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-trash-fill" viewBox="0 0 16 16">
                      <path
                        d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">{{modalTitle}}</h5>
                  <button type="button" class="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div class="d-flex flex-row bd-highlight">
                    <div class="p-4 w-100 bd-highlight">

                      <div class="form-group form-floating">
                        <select [(ngModel)]="PageTitle" class="form-select" id="PageTitle">
                          <option *ngFor="let pageTitle of pageTitles" value={{pageTitle}}>{{pageTitle}}</option>
                        </select>
                        <label for="PageTitle">Page Title <req>*</req></label>
                      </div>

                      <div class="form-group form-floating">
                        <select [(ngModel)]="PostType" class="form-select" id="PostType" (change)=ptypechange()>
                          <option *ngFor="let postType of postTypes" value={{postType.name}}>{{postType.name}}</option>
                        </select>
                        <label for="PostType">Post Type <req>*</req></label>
                      </div>

                      <!-- div *ngIf="PostId==0" class="form-group form-floating"-->
                      <div class="form-group form-floating">
                        <input type="text" class="form-control" [(ngModel)]="PostCode" id="PostCode">
                        <label for="PostCode">Post Code <req>*</req></label>
                      </div>



                      <div class="form-group form-floating">
                        <select [(ngModel)]="PostStatus" class="form-select" id="PostStatus">
                          <option *ngFor="let postStatus of postSTATUS" value={{postStatus}}>{{postStatus}}</option>
                        </select>
                        <label for="PostStatus">Post Status <ng-container class="req">*</ng-container></label>
                      </div>


                    </div>

                    <div *ngIf="!(PostType=='' || PostType==null)" class="p-4 w-50 bd-highlight">
                      <img [src]=PhotoFilePath height="200px;" width="300px;">
                      <input type="file" (change)="uploadPhoto($event,ptype.id)" class="mt-2" />
                    </div>
                  </div>

                  <div class="d-flex flex-row bd-highlight">
                    <div class="p-4 w-100 bd-highlight">

                      <div *ngFor="let par of ptype.param; let i=index" class="form-group form-floating">
                        <textarea *ngIf="!(ptype.name=='Service' && i==1)" class="form-control" style="min-height: {{ptype.paramlen[i]}}em;
      overflow: auto;" [(ngModel)]="Params[i]" id="PostDetails" contentEditable></textarea>
                        <select *ngIf="(ptype.name=='Service' && i==1)" [(ngModel)]="Params[i]" class="form-select"
                          id="PostDetails">
                          <option *ngFor="let serviceIcon of serviceIcons" value={{serviceIcon}}>{{serviceIcon}}
                          </option>
                        </select>
                        <label for="PostDetails">{{par}} <req *ngIf=ptype.required[i]>*</req></label>
                      </div>

                    </div>
                  </div>

                  <button type="button" (click)="addPostClick(ptype.id)" *ngIf="PostId==0"
                    class="btn btn-primary float-end">
                    Add Post
                  </button>

                  <button type="button" (click)="updatePost(ptype.id)" *ngIf="PostId!=0"
                    class="btn btn-primary float-end">
                    Update Post
                  </button>

                </div>

              </div>
            </div>
          </div>

          <div class="modal fade" id="viewMoreModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div class="d-flex flex-row bd-highlight">
                    <div class="p-4 w-100 bd-highlight">
                      {{viewMoreString}}
                    </div>
                  </div>

                  <button type="button" class="btn btn-primary float-end" data-bs-dismiss="modal">
                    OK
                  </button>

                </div>

              </div>
            </div>
          </div>
        </div>
        <!-- ========= end Site Management -->

        <!-- Activity
        ======================-->
        <div *ngIf=menu[7].status>
          <table class="table table-striped">
            <!--thead>
              <tr>
                <th>Matching ID</th>
                <th>Matching Details</th>
                <th>Point For Male</th>
                <th>Point For Female</th>
                <th>Options</th>
              </tr>
            </thead-->
            <tbody>
              <tr *ngFor="let log of adminLog">
                <td>{{log.actionTime}}</td>
                <td> {{getAdminName(log.adminId) + ' ' + service.actionType[log.action] + ' of '}} 
                  <a [routerLink]="['/edit-profile',log.userType,log.userId]" target="_blank">
                    {{getName(log.userId,log.userType.toString())}} 
                  </a>
                  <ng-container *ngIf="log.xuserId!=null"> for 
                    <a [routerLink]="['/edit-profile',reverseGender(log.userType),log.xuserId]" target="_blank">
                      {{getName(log.xuserId,reverseGender(log.userType).toString())}} 
                    </a>
                  </ng-container>
                  <ng-container *ngIf="log.note!=null"> due to 
                    <strong>{{rejectionNote[log.note]}}</strong>
                  </ng-container>
                </td>

                <!--If rejected a profile request, then undo option-->
                <td><ng-container *ngIf="log.action==5">
                  <button type="button" class="btn btn-info" (click)="undoAccept(log)">Undo & Accept</button>
                </ng-container></td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- ========= end Activity -->

        <!-- Convention User Profile
        ======================-->
        <div *ngIf=menu[8].status>
          <div class="row">
            <div class="col-sm-3 col-md-6 ml-auto">
              <ngb-pagination [collectionSize]="(conventionuser? convfemalesFiltered.length : convmalesFiltered.length)" [(page)]="page" aria-label="Default pagination"></ngb-pagination>
            </div>
            <div class="col-sm-3 mr-auto">
              <div class="input-group" [ngClass]="{'input-group-focus':focus===true}">
                  <input type="text" [(ngModel)]="userFilter" class="form-control" placeholder="Search by Name" aria-describedby="basic-addon1"
                  (focus)="focus=true" (blur)="focus=false" (keyup)="userFilterFn1()" >
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-search" aria-hidden="true"></i></span>
                  </div>
              </div>
            </div>
            </div>
            <br>
            (Click on Table Header button to Sort Ascending or Descending)

            <table class="table table-striped">
              <thead>
                <tr>
                  <th (click)="sortResult1('userId',sortasc)" style="cursor:pointer;"> ID 
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z"/></svg>
                  </th>
                  <th (click)="sortResult1('fullName',sortasc)" style="cursor:pointer;">Name 
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z"/></svg>
                  </th>
                  <th (click)="sortResult1('email',sortasc)" style="cursor:pointer;">Email
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z"/></svg>
                  </th>
                  <th (click)="sortResult1('cellPhone',sortasc)" style="cursor:pointer;">Cell Phone
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z"/></svg>
                  </th>
                  <th>Age </th>
                  <th>Address </th>
                  <th>Options</th>
                </tr>
              </thead>
              <tbody *ngIf="!conventionuser">
                <!--tr *ngFor="let user of (menu[2].status ? maleusers : femaleusers)"><ng-container> -->
                <tr *ngFor="let user of convmalesFiltered; let i=index">
                  <ng-container *ngIf="i>=(page-1)*itemPerPage && i<page*itemPerPage">
                    <td style="vertical-align: middle;">{{user.userId}}</td>
                    <td style="vertical-align: middle;">
                      <a [routerLink]="['#']" title="View Details">
                        {{user.fullName}}</a></td>
                    <td style="vertical-align: middle; cursor:pointer; color:blueviolet" data-bs-toggle="modal" data-bs-target="#emailModal" (click)="emailUser(user)"
                    title="Email User">{{user.email.substring(0, user.email.indexOf('@'))+'\n'+user.email.substring(user.email.indexOf('@'))}}</td>
                    <td style="vertical-align: middle;">{{user.cellPhone}}</td>
                    <td style="vertical-align: middle;">{{getAge(user.birthYear)}}</td>
                    <td style="vertical-align: middle;">{{user.address+", "+user.state}}</td>
                    
                    <td style="vertical-align: middle;">
                      <button type=button class="btn btn-light mr-1" data-bs-toggle="modal" data-bs-target="#viewUserModal" (click)="viewUser(user.userId, user.gender)" title="View/Edit">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-hearts" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M11.5 1.246c.832-.855 2.913.642 0 2.566-2.913-1.924-.832-3.421 0-2.566ZM9 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h10s1 0 1-1-1-4-6-4-6 3-6 4Zm13.5-8.09c1.387-1.425 4.855 1.07 0 4.277-4.854-3.207-1.387-5.702 0-4.276ZM15 2.165c.555-.57 1.942.428 0 1.711-1.942-1.283-.555-2.281 0-1.71Z"/>
                        </svg>
                      </button>
                      
                      <button type=button class="btn btn-light mr-1" (click)="deleteUser(user)" title="Delete USer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-trash-fill" viewBox="0 0 16 16">
                          <path
                            d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                        </svg>
                      </button>
                    </td>
                  </ng-container>
                </tr>
              </tbody>
              <tbody *ngIf="conventionuser">
                <!--tr *ngFor="let user of (menu[2].status ? maleusers : femaleusers)"><ng-container> -->
                <tr *ngFor="let user of convfemalesFiltered; let i=index">
                  <ng-container *ngIf="i>=(page-1)*itemPerPage && i<page*itemPerPage">
                    <td style="vertical-align: middle;">{{user.userId}}</td>
                    <td style="vertical-align: middle;">
                      <a [routerLink]="['#']" title="View Details">
                        {{user.fullName}}</a></td>
                    <td style="vertical-align: middle; cursor:pointer; color:blueviolet" data-bs-toggle="modal" data-bs-target="#emailModal" (click)="emailUser(user)"
                    title="Email User">{{user.email.substring(0, user.email.indexOf('@'))+'\n'+user.email.substring(user.email.indexOf('@'))}}</td>
                    <td style="vertical-align: middle;">{{user.cellPhone}}</td>
                    <td style="vertical-align: middle;">{{getAge(user.birthYear)}}</td>
                    <td style="vertical-align: middle;">{{user.address+", "+user.state}}</td>
                    
                    <td style="vertical-align: middle;">
                      <button type=button class="btn btn-light mr-1" data-bs-toggle="modal" data-bs-target="#viewUserModal" (click)="viewUser(user.userId, user.gender)" title="View/Edit">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-hearts" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M11.5 1.246c.832-.855 2.913.642 0 2.566-2.913-1.924-.832-3.421 0-2.566ZM9 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h10s1 0 1-1-1-4-6-4-6 3-6 4Zm13.5-8.09c1.387-1.425 4.855 1.07 0 4.277-4.854-3.207-1.387-5.702 0-4.276ZM15 2.165c.555-.57 1.942.428 0 1.711-1.942-1.283-.555-2.281 0-1.71Z"/>
                        </svg>
                      </button>
                      
                      <button type=button class="btn btn-light mr-1" (click)="deleteUser(user)" title="Delete USer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-trash-fill" viewBox="0 0 16 16">
                          <path
                            d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                        </svg>
                      </button>
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>

            <!-- View User Modal
        ======================-->
          <div class="modal fade" id="viewUserModal" tabindex="-1" aria-labelledby="exampleModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">View User</h5>
                <button type="button" class="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="d-flex flex-row bd-highlight">
                  <div class="p-4 w-100 bd-highlight">
                    <div class="form-group form-floating">
                      <input type="text" [(ngModel)]="convuser.fullName" class="form-control"
                        placeholder="Full Name" id="fullName" disabled>
                      <label for="FullName">Full Name</label>
                    </div>

                    <div class="form-group form-floating">
                      <input type="text" [(ngModel)]="convuser.cellPhone" class="form-control" placeholder="Cell Phone Number"
                        id="cellPhone" disabled>
                      <label for="CellPhone">Cell Phone</label>
                    </div>

                    <div class="form-group form-floating">
                      <input type="text" [(ngModel)]="convuser.email" class="form-control" placeholder="Email"
                        id="email" disabled>
                      <label for="Email">Email</label>
                    </div>

                    <div class="form-group form-floating">
                      <input type="text" [(ngModel)]="convuser.birthYear" class="form-control" placeholder="Birth Year"
                         disabled>
                      <label for="Email">Birth Year</label>
                    </div>

                    <div class="form-group form-floating">
                      <input type="text" [(ngModel)]="convuser.address" class="form-control" placeholder="Birth Year"
                        id="address" disabled>
                      <label for="address">Address</label>
                    </div>

                    <div class="form-group form-floating">
                      <input type="text" [(ngModel)]="convuser.state" class="form-control" placeholder="State"
                        id="state" disabled>
                      <label for="state">State</label>
                    </div>

                    <div class="form-group form-floating">
                      <input type="text" [(ngModel)]="convuser.highestEducation" class="form-control" placeholder="Educational Qualification"
                        id="highestEducation" disabled>
                      <label for="highestEducation">Educational Qualification</label>
                    </div>

                    <div class="form-group form-floating">
                      <input type="text" [(ngModel)]="convuser.immigrationStatus" class="form-control" placeholder="Immigration Status"
                        id="immigrationStatus" disabled>
                      <label for="immigrationStatus">Immigration Status</label>
                    </div>

                    <div class="form-group form-floating">
                      <input type="text" [(ngModel)]="convuser.employed" class="form-control" placeholder="Yes/No"
                        id="employed" disabled>
                      <label for="employed">Employed?</label>
                    </div>

                    <div class="form-group form-floating">
                      <input type="text" [(ngModel)]="convuser.employment" class="form-control" placeholder="Employment Organization"
                        id="employment" disabled>
                      <label for="employment">Employment Organization</label>
                    </div>

                    <div class="form-group form-floating">
                      <input type="text" [(ngModel)]="convuser.income" class="form-control" placeholder="Yearly Income ($)"
                        id="income" disabled>
                      <label for="income">Yearly Income ($)</label>
                    </div>

                    <div class="form-group form-floating">
                      <input type="text" [(ngModel)]="convuser.familyBrief" class="form-control" placeholder="Additional Information"
                        id="familyBrief" disabled>
                      <label for="familyBrief">Additional Information</label>
                    </div>

                    <div class="form-group form-floating">
                      <textarea [(ngModel)]="convuser.cv" class="form-control" placeholder="Admin Comment"
                        id="cv"></textarea>
                      <label for="cv">Admin Comment</label>
                    </div>

                    
                  </div>
                </div>

                <button type="button" (click)="saveAdminComment(convuser.gender)" class="btn btn-primary float-end">
                  Save
                </button>

              </div>


            </div>
          </div>
        </div>
        </div>
        <!-- ========= end Convention User -->

        <!-- Sample Table
        ======================-->
        
        <!-- ========= end Sample Table -->

        <!-- Common Modals
        ======================-->

        <!-- Send Email Modal
        ======================-->
        <div class="modal fade" id="emailModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>
                  <h5 class="modal-title" id="exampleModalLabel">Send Email to {{emailUserName}}</h5>
                  <p>{{emailUserEmail}}</p>
                  
                </div>
                <div class="modal-body">
                  <div class="d-flex flex-row bd-highlight">
                    <div class="p-4 w-100 bd-highlight">
                      <!--div class="form-group form-floating">
                        <input type="text" [(ngModel)]="emailUserEmail" class="form-control"
                          placeholder="Email Subject" id="emailUserEmail">
                        <label for="emailUserEmail">User Email</label>
                      </div-->
                      <div class="form-group form-floating">
                        <input type="text" [(ngModel)]="emailSubject" class="form-control"
                          placeholder="Email Subject" id="emailSubject">
                        <label for="emailSubject">Email Subject</label>
                      </div>
                      <div class="form-group form-floating">
                        <textarea class="form-control" style="min-height: 10em;
                          overflow: auto;" [(ngModel)]="emailBody" id="emailBody" contentEditable></textarea>
                        <label for="emailBody">Type your message here</label>
                      </div>

                    </div>
                  </div>

                  <button type="button" (click)="sendEmail()"
                    class="btn btn-primary float-end">
                    Send
                  </button>

                </div>

              </div>
            </div>
          </div>

      </div>
    </div>

  </div>


</section><!-- #about -->