<!--==========================
  Our Portfolio Section
============================-->
<section id="portfolio" class="wow fadeInUp">
  <div class="container">
    <div class="section-header">
      <h2>Events</h2>
      <br>
      <p>{{eventHeading}}</p>
      </div>
  </div>

  <div class="container-fluid">
    <div class="row ml-auto mr-auto">  <!--<div class="row no-gutters"> if I want no padding-->

      <div class="col-lg-3 col-md-4">
        <div class="portfolio-item wow fadeInUp">
          <a href="{{PhotoUrl+events[0].postPhoto}}" class="portfolio-popup">
            <img src="{{PhotoUrl+events[0].postPhoto}}" alt="" style="height:200px;">
          <div class="portfolio-overlay">
            <div class="portfolio-info"><h2 class="wow fadeInUp">{{events[0].param1}}</h2></div>
          </div>
          </a>
        </div>

        <div class="section-header" style="padding: 10px;">
          <h6 style="text-align: center; color: white;">{{events[0].param1}}</h6>
          <p>{{events[0].param2}}</p>
          <p>{{events[0].param3}}</p>
          <p style="text-align: center;">
            <button type="button" class="btn btn-warning btn-sm btn-link" (click)=viewMore(0)
            data-bs-toggle="modal" data-bs-target="#viewEventModal">View Details</button>
          </p>
        </div>
      </div>

      <div  class="col-lg-3 col-md-4">
        <div class="portfolio-item wow fadeInUp">
          <a href="{{PhotoUrl+events[1].postPhoto}}" class="portfolio-popup">
            <img src="{{PhotoUrl+events[1].postPhoto}}" alt="" style="height:200px;">
          <div class="portfolio-overlay">
            <div class="portfolio-info"><h2 class="wow fadeInUp">{{events[1].param1}}</h2></div>
          </div>
          </a>
        </div>

        <div class="section-header" style="padding: 10px;">
          <h6 style="text-align: center; color: white;">{{events[1].param1}}</h6>
          <p>{{events[1].param2}}</p>
          <p>{{events[1].param3}}</p>
          <p style="text-align: center;">
            <button type="button" class="btn btn-warning btn-sm btn-link" (click)=viewMore(1)
            data-bs-toggle="modal" data-bs-target="#viewEventModal">View Details</button>
          </p>
        </div>
      </div>

      <div  class="col-lg-3 col-md-4">
        <div class="portfolio-item wow fadeInUp">
          <a href="{{PhotoUrl+events[2].postPhoto}}" class="portfolio-popup">
            <img src="{{PhotoUrl+events[2].postPhoto}}" alt="" style="height:200px;">
          <div class="portfolio-overlay">
            <div class="portfolio-info"><h2 class="wow fadeInUp">{{events[2].param1}}</h2></div>
          </div>
          </a>
        </div>

        <div class="section-header" style="padding: 10px;">
          <h6 style="text-align: center; color: white;">{{events[2].param1}}</h6>
          <p>{{events[2].param2}}</p>
          <p>{{events[2].param3}}</p>
          <p style="text-align: center;">
            <button type="button" class="btn btn-warning btn-sm btn-link" (click)=viewMore(2)
            data-bs-toggle="modal" data-bs-target="#viewEventModal">View Details</button>
          </p>
        </div>
      </div>

      <div  class="col-lg-3 col-md-4">
        <div class="portfolio-item wow fadeInUp">
          <a href="{{PhotoUrl+events[3].postPhoto}}" class="portfolio-popup">
            <img src="{{PhotoUrl+events[3].postPhoto}}" alt="" style="height:200px;">
          <div class="portfolio-overlay">
            <div class="portfolio-info"><h2 class="wow fadeInUp">{{events[3].param1}}</h2></div>
          </div>
          </a>
        </div>

        <div class="section-header" style="padding: 10px;">
          <h6 style="text-align: center; color: white;">{{events[3].param1}}</h6>
          <p>{{events[3].param2}}</p>
          <p>{{events[3].param3}}</p>
          <p style="text-align: center;">
            <button type="button" class="btn btn-warning btn-sm btn-link" (click)=viewMore(3)
            data-bs-toggle="modal" data-bs-target="#viewEventModal">View Details</button>
          </p>
          <br>
          <button type="button" class="btn btn-outline-danger" (click)=viewMoreEvent()
          style="float: right;">{{moreless}}</button>
          <br>
        </div>
      </div>
      </div>


      <div *ngIf=moreEvent class="row">
      <div *ngFor="let event of events | slice:4; let i=index" class="col-lg-3 col-md-4">
        <div class="portfolio-item wow fadeInUp">
          <a href="{{PhotoUrl+event.postPhoto}}" target="_blank" class="portfolio-popup">
            <img src="{{PhotoUrl+event.postPhoto}}" alt="" style="height:200px;">
          <div class="portfolio-overlay">
            <div class="portfolio-info"><h2 class="wow fadeInUp">{{event.param1}}</h2></div>
          </div>
          </a>
        </div>

        <div class="section-header" style="padding: 10px;">
          <h6 style="text-align: center; color: white;">{{event.param1}}</h6>
          <p>{{event.param2}}</p>
          <p>{{event.param3}}</p>
          <p style="text-align: center;">
            <button type="button" class="btn btn-warning btn-sm btn-link" (click)=viewMore(i)
            data-bs-toggle="modal" data-bs-target="#viewEventModal">View Details</button>
          </p>
        </div>
      </div>
      </div>


      <!-- News <section>
        ================--
      </section>
      <div class="col-lg-3 col-md-4" style="background-color:#7E2578;">
        <div class="box wow fadeInLeft" data-wow-delay="0.2s">
            <h4>Seminar on Marriage</h4>

            <h6>Oct 08, 2021 3PM: </h6>
            <p style="text-align: justify">A seminar on Isalmic Marriage will be held at New York</p>

            <h6>Oct 08, 2021 3PM: </h6>
            <p style="text-align: justify">A seminar on Isalmic Marriage will be held at New York</p>
        </div>
      </div>

      <div class="col-lg-3 col-md-4" style="background-color:#7E2578;">
        <div class="box wow fadeInLeft" data-wow-delay="0.2s">
            <h4>Education Program</h4>

            <h6>Oct 08, 2021 3PM: </h6>
            <p style="text-align: justify">A seminar on Isalmic Marriage will be held at New York</p>

            <h6>Oct 08, 2021 3PM: </h6>
            <p style="text-align: justify">A seminar on Isalmic Marriage will be held at New York</p>

        </div>
      </div>

      <div class="col-lg-3 col-md-4" style="background-color:#7E2578;">
        <div class="box wow fadeInLeft" data-wow-delay="0.2s">
            <h4>Post Marriage Reception</h4>

            <h6>Oct 08, 2021 3PM: </h6>
            <p style="text-align: justify">A seminar on Isalmic Marriage will be held at New York</p>

            <h6>Oct 08, 2021 3PM: </h6>
            <p style="text-align: justify">A seminar on Isalmic Marriage will be held at New York</p>
        </div>
      </div>

      <div class="col-lg-3 col-md-4" style="background-color:#7E2578;">
        <div class="box wow fadeInLeft" data-wow-delay="0.2s">
            <h4>Islamic Cultural Program</h4>

            <h6>Oct 08, 2021 3PM: </h6>
            <p style="text-align: justify">A seminar on Isalmic Marriage will be held at New York</p>

            <h6>Oct 08, 2021 3PM: </h6>
            <p style="text-align: justify">A seminar on Isalmic Marriage will be held at New York</p>
        </div>
      </div>  -->



  </div>
</section><!-- #portfolio -->


<div class="modal fade" id="viewEventModal" tabindex="-1"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog modal-xl modal-dialog-centered">
<div class="modal-content">
    <div class="modal-header">
      <button type="button" class="btn-close float-end" data-bs-dismiss="modal"
      aria-label="Close"></button>
      <h4 style="text-align:center;"><a>{{currentEvent.param1}}</a></h4>
      <p></p>
      <p style="text-align:center;"><a>{{currentEvent.param2}}</a></p>
      <p style="text-align:center;"><a>{{currentEvent.param3}}</a></p>

    </div>
    <div class="modal-body">
    <div class="d-flex flex-row bd-highlight">
        <div class="p-4 w-100 bd-highlight">
          <p style="text-align: center"><img src="{{PhotoUrl+currentEvent.postPhoto}}" style="height: 300px; float:center;"
               class="img-rounded img-responsive" alt="Rounded Image"></p>
          <p style="text-align:justify;" *ngFor="let para of currentEventDetails">
            <br>{{para}}</p>
        </div>
    </div>

        <button type="button" class="btn btn-danger float-end"
        data-bs-dismiss="modal">
        OK
        </button>

    </div>

</div>
</div>
</div>
