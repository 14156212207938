<!--==========================
  Services Section
============================-->
<section id="services">
  <div class="container">
    <div class="section-header">
      <h2>OUR SERVICES</h2>
      <br>
      <p>{{serviceHeading}}
        <button *ngIf="false" type="button" class="btn btn-light mr-1"
        data-toggle="modal" data-target="#exampleModal"
      (click)="editClick(dataItem)"
      data-backdrop="static" data-keyboard="false">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
          </svg>
        </button>
      </p>
    </div>

    <div class="row">

      <div class="col-lg-4" *ngFor="let service of services; let i=index">
        <div class="box wow fadeInLeft" (click)=viewMore(service)
        data-bs-toggle="modal" data-bs-target="#viewMoreModal">
          <div class="icon"><i class="fa fa-{{service.param2}}"></i></div>
          <h4 class="title"><a>{{service.param1}}</a></h4>
          <p *ngIf="service.param3.length<=maxServiceLen" class="description">{{service.param3}}</p>
          <p *ngIf="service.param3.length>maxServiceLen" class="description">
            {{service.param3.substring(0,maxServiceLen)}} ......
          </p>
        </div>
      </div>


    </div>

    <div class="modal fade" id="viewMoreModal" tabindex="-1"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
        <div class="modal-header">
          <h4 class="title"><a>{{currentServiceHeading}}</a></h4>
            <!--button type="button" class="btn-close float-end" data-bs-dismiss="modal"
            aria-label="Close"></button-->
        </div>
        <div class="modal-body">
        <div class="d-flex flex-row bd-highlight">
            <div class="p-4 w-100 bd-highlight">
              <p *ngFor="let para of currentService">{{para}}<br></p>
            </div>
        </div>

            <button type="button" class="btn btn-danger float-end"
            data-bs-dismiss="modal">
            OK
            </button>

        </div>

    </div>
    </div>
    </div>

  </div>
</section><!-- #services -->
