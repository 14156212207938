<!--==========================
  FAQ Section
============================-->
<section id="faq" class="wow fadeInUp">
  <div class="container">
  <div class="section-header">
      <h2>FREQUENTLY ASKED QUESTIONS</h2>
      <br>
      <p>{{faqHeading.param1}}</p>
      </div>
    <div class="row" *ngIf=isHome() >
      <div class="col-lg-6 about-img">
        <img src="{{PhotoUrl+faqHeading.postPhoto}}" alt="">
      </div>

      <div class="col-lg-6 content">
        <div class="accordion accordion-flush" id="accordionFlushExample">
        <div class="accordion-item" *ngFor="let faq of faqs | slice:0:3; let i=index">
          <h2 class="accordion-header" [attr.id]="'flush-heading'+i">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#flush-collapse'+i" aria-expanded="false" [attr.aria-controls]="'flush-collapse'+i">
              {{faq.param1}}
            </button>
          </h2>
          <div [attr.id]="'flush-collapse'+i" class="accordion-collapse collapse" [attr.aria-labelledby]="'flush-heading'+i" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body">{{faq.param2}}</div>
          </div>
        </div>
        <br><br>
        <button type="button" class="btn btn-outline-default" [routerLink]="['/faqs']"
        style="float: right;">{{moreless}}</button>

      </div>
      </div>
    </div>

    <div class="row" *ngIf=!isHome() >
      <div class="col-lg-9 content mr-auto ml-auto">
        <div class="accordion accordion-flush" id="accordionFlushExample">
        <div class="accordion-item" *ngFor="let faq of faqs; let i=index">
          <h2 class="accordion-header" [attr.id]="'flush-heading'+i">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#flush-collapse'+i" aria-expanded="false" [attr.aria-controls]="'flush-collapse'+i">
              {{faq.param1}}
            </button>
          </h2>
          <div [attr.id]="'flush-collapse'+i" class="accordion-collapse collapse" [attr.aria-labelledby]="'flush-heading'+i" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body">{{faq.param2}}</div>
          </div>
        </div>


      </div>
      </div>
    </div>

  </div>
</section><!-- #about -->
