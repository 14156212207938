
  <div class="wrapper">
      <div class="page-header page-header-xs" data-parallax="true" style="background-image: url('./assets/img/furkan-cover-photo.jpg');">
          <div class="filter"></div>
      </div>

      <div class="section-low-padding profile-content">
          <div class="container">
              <div class="owner">
                <div class="avatar" (mouseover)="pad=true" (mouseout)="pad=false" data-bs-toggle="modal" data-bs-target="#photoModal"  placement="top" ngbTooltip="View Picture">
                    <img *ngIf="!pad" [src]="PhotoFilePath" alt="Circle Image" class="img-circle img-no-padding img-responsive" height="150px;" width="200px;">
                    <img *ngIf="pad" [src]="PhotoFilePath" alt="Circle Image" class="img-circle img-padding img-responsive" height="150px;" width="200px;">
                </div>
                  <div class="name">
                      <h4 class="title">{{currentUser.fullName}}</h4>
                      <h6 class="description">{{currentUser.gender}} : Age {{currentUser.age}}</h6>
                  </div>
              </div>
            </div>
  </div>
  </div>

  <div class="modal fade" id="photoModal" tabindex="-1"
      aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-md modal-dialog-centered">
  <div class="modal-content">
      <div class="modal-header">
          <img [src]="PhotoFilePath" alt="{{PhotoFilePath}}" class="img-rounded img-padding img-responsive">
          <button type="button" class="btn-close float-end" data-bs-dismiss="modal"
          aria-label="Close"></button>
      </div>

  </div>
  </div>
  </div>

  <!--==========================
    Our Portfolio Section
  ============================-->
  <section id="portfolio" class="wow fadeInUp">

    <div class="container-fluid">
      <div class="row no-gutters">

        <div class="col-lg-3 col-md-4">
          <div class="portfolio-item wow fadeInUp">
            <a href="{{PhotoUrl+gallery[0]}}" class="portfolio-popup">
              <img src="{{PhotoUrl+gallery[0]}}" alt="" height="200px;">
              <div class="portfolio-overlay">
                <div class="portfolio-info"><h2 class="wow fadeInUp"></h2></div>
              </div>
            </a>
          </div>
        </div>

        <div class="col-lg-3 col-md-4">
          <div class="portfolio-item wow fadeInUp">
            <a href="{{PhotoUrl+gallery[1]}}" class="portfolio-popup">
              <img src="{{PhotoUrl+gallery[1]}}" alt="" height="200px;">
              <div class="portfolio-overlay">
                <div class="portfolio-info"><h2 class="wow fadeInUp"></h2></div>
              </div>
            </a>
          </div>
        </div>

        <div class="col-lg-3 col-md-4">
          <div class="portfolio-item wow fadeInUp">
            <a href="{{PhotoUrl+gallery[2]}}" class="portfolio-popup">
              <img src="{{PhotoUrl+gallery[2]}}" alt="" height="200px;">
              <div class="portfolio-overlay">
                <div class="portfolio-info"><h2 class="wow fadeInUp"></h2></div>
              </div>
            </a>
          </div>
        </div>

        <div class="col-lg-3 col-md-4">
          <div class="portfolio-item wow fadeInUp">
            <a href="{{PhotoUrl+gallery[3]}}" class="portfolio-popup">
              <img src="{{PhotoUrl+gallery[3]}}" alt="" height="200px;">
              <div class="portfolio-overlay">
                <div class="portfolio-info"><h2 class="wow fadeInUp"></h2></div>
              </div>
            </a>
          </div>
        </div>
      </div>

      <div class="modal fade" id="galleryModal" tabindex="-1"
          aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Change Your Gallery Photos</h5>
              <button type="button" class="btn-close float-end"
              data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="d-flex flex-row bd-highlight">
                <div class="row">
                  <div class="col-md-6" *ngFor="let photo of gallery; let i=index">
                    <img [src]="PhotoUrl+photo" class="img-rounded img-padding img-responsive"
                    style="width:100%; height:200px;">
                    <div class="row form-group files">
                        <label for="file-input">Change Photo</label>
                        <input type="file" title="image" id="file-input"
                        (change)="uploadFile($event,i)" class="mt-2 col-md-8"/>
                    </div>
                  </div>

                </div>
            </div>
          </div>

      </div>
      </div>
      </div>

    </div>
  </section><!-- #portfolio -->
  <br/>

  
  <div class="col-lg-9 content ml-auto mr-auto">
    <div class="accordion accordion-flush" id="accordionFlushExample">
    <div class="accordion-item">
      <h2 class="accordion-header" id="flush-headingOne">
        <button class="btn btn-danger accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
          GENERAL INFORMATION
        </button>
      </h2>
      <div id="flush-collapseOne" class="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
        <div class="accordion-body">

          <table class="table">

            <tbody>
              <tr>
                <th scope="row">Full Name</th>
                <td >{{currentUser.fullName}}</td>
              </tr>
              <tr>
                <th scope="row"> Nickname(s)</th>
                <td >{{currentUser.nickName}}</td>
              </tr>
              <tr>
                <th scope="row">Gender</th>
                <td >{{currentUser.gender}}</td>
              </tr>
              <!--Not to show address--
              <tr>
                <th scope="row">Address</th>
                <td colspan="3">{{currentUser.address}}</td>
              </tr>
              -->
              <tr>
                <th scope="row"> City</th>
                <td >{{currentUser.city}}</td>
              </tr>
              <tr>
                <th scope="row">State</th>
                <td>{{currentUser.state}}</td>

              </tr>
              <!--Not to show address--
              <tr>
                <th scope="row"> ZIP Code</th>
                <td >{{currentUser.zip}}</td>
              </tr>
              -->
              <!--Not to show phone--
              <tr>
                <th scope="row">Cell Phone</th>
                <td>{{currentUser.cellPhone}}</td>
              </tr>
              <tr>
                <th scope="row">Work Phone</th>
                <td >{{currentUser.workPhone}}</td>
              </tr>
              <tr>
                <th scope="row">Home Phone</th>
                <td>{{currentUser.homePhone}}</td>

              </tr>
              -->
              <tr>
                <th scope="row">Email</th>
                <td >{{currentUser.email}}</td>
              </tr>
              <tr>
                <th scope="row">Personal Website</th>
                <td>{{currentUser.personalWebsite}}</td>
              </tr>

              <tr>
                <th scope="row"> Year of Birth</th>
                <td >{{currentUser.birthYear}}</td>
              </tr>
              <tr>
                <th scope="row">Age</th>
                <td>{{currentUser.age}}</td>
              </tr>
              <tr>
                <th scope="row"> Height (cm)</th>
                <td >{{currentUser.height}}</td>
              </tr>
              <tr>
                <th scope="row">Weight (kg)</th>
                <td>{{currentUser.weight}}</td>

              </tr>

              <tr>
                <th scope="row"> Place of Birth</th>
                <td >{{currentUser.placeOfBirth}}</td>
              </tr>
              <tr>
                <th scope="row">Abnormal Health Conditions</th>
                <td>{{currentUser.health}}</td>

              </tr>
              <tr>
                <th scope="row">Current Marital Status</th>
                <td >{{currentUser.maritalStatus}}</td>
              </tr>
              <tr>
                <th scope="row">Children</th>
                <td>{{currentUser.children}}</td>

              </tr>
              <tr>
                <th scope="row">Children Number</th>
                <td >{{currentUser.childrenNumber}}</td>
              </tr>
              <tr>
                <th scope="row"> Ages</th>
                <td>{{currentUser.childrenAges}}</td>
              </tr>
              <tr>
                <th scope="row"> Immigration Status</th>
                <td >{{currentUser.immigrationStatus}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="flush-headingTwo">
        <button class="btn btn-danger accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
          Academic & Professional Background
        </button>
      </h2>
      <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
        <div class="accordion-body">
          <h6>Academic Background</h6>

          <table class="table">

            <tbody>
              <tr>
                <th scope="row">High School</th>
                <td >{{currentUser.highSchool}}</td>
              </tr>
              <tr>
                <th scope="row">Year</th>
                <td >{{currentUser.highSchoolYear}}</td>
              </tr>
              <tr>
                <th scope="row">Bachelors</th>
                <td >{{currentUser.bachelors}}</td>
              </tr>
              <tr>
                <th scope="row">Year</th>
                <td >{{currentUser.bachelorsYear}}</td>
                <tr>
                  <th scope="row">Masters in</th>
                  <td >{{currentUser.masters}}</td>
                </tr>
                <tr>
                  <th scope="row">Year</th>
                  <td >{{currentUser.mastersYear}}</td>
                </tr>
                <tr>
                  <th scope="row">Doctorate in</th>
                  <td >{{currentUser.doctorate}}</td>
                </tr>
                <tr>
                  <th scope="row">Year</th>
                  <td >{{currentUser.doctorateYear}}</td>
                </tr>
                <tr>
                  <th scope="row">Other Degree(s)</th>
                  <td >{{currentUser.otherDegree}}</td>
                </tr>
                <tr>
                  <th scope="row">Year(s)</th>
                  <td >{{currentUser.otherDegreeYear}}</td>
                </tr>

              <h6>Professional Background</h6>
              <tr>
                <th scope="row">Employment Status</th>
                <td >{{currentUser.employed}}</td>
              </tr>
              <tr>
                <th scope="row">Employment Institute</th>
                <td >{{currentUser.employment}}</td>
              </tr>
              <tr>
                <th scope="row">Position</th>
                <td >{{currentUser.position}}</td>
              </tr>
              <!--Not to show income--
              <tr>
                <th scope="row">Yearly Income ($)</th>
                <td >{{currentUser.income}}</td>
              </tr>
              -->
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="flush-headingThree">
        <button class="btn btn-danger accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">

  Parent/Guardian's Information
        </button>
      </h2>
      <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
        <div class="accordion-body">

          <table class="table">
            <tbody>
              <tr>
                <th scope="row">Names and Relationships</th>
                <td >{{currentUser.guarName}}</td>
              </tr>
              <!--Not to show address--
              <tr>
                <th scope="row">Address</th>
                <td >{{currentUser.guarAddress}}</td>
              </tr-->
              <tr>
                <th scope="row">City</th>
                <td >{{currentUser.guarCity}}</td>
              </tr>
              <tr>
                <th scope="row">State</th>
                <td >{{currentUser.guarState}}</td>
              </tr>
              <tr>
                <th scope="row">Country</th>
                <td >{{currentUser.guarCountry}}</td>
              </tr>
              <!--Not to show phone--
              <tr>
                <th scope="row">Phone</th>
                <td >{{currentUser.guarPhone}}</td>
              </tr-->
              <tr>
                <th scope="row">Email</th>
                <td >{{currentUser.guarEmail}}</td>
              </tr>
              <tr>
                <th scope="row">Profession</th>
                <td >{{currentUser.guarProfession}}</td>
              </tr>
              <tr>
                <th scope="row">Additional Information</th>
                <td >{{currentUser.familyBrief}}</td>
              </tr>

            </tbody>
          </table>

          </div>
        </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="flush-headingFour">
        <button class="btn btn-danger accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">

  Personal Information
        </button>
      </h2>
      <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
        <div class="accordion-body">

          <table class="table">
            <tbody>
              <tr>
                <th scope="row">Religious Practice</th>
                <td >{{currentUser.religiousPractice}}</td>
              </tr>
              <tr>
                <th scope="row">Other Religious Practice</th>
                <td >{{currentUser.religiousPracticeOther}}</td>
              </tr>
              <tr>
                <th scope="row">Regular Religious Activities</th>
                <td >{{currentUser.religiousPracticeBrief}}</td>
              </tr>
              <tr>
                <th scope="row">Revert Status</th>
                <td >{{currentUser.revertTime}}</td>
              </tr>

            </tbody>
          </table>

            <div class="col-sm-12" *ngIf=isMale()>

            <table class="table">
              <tbody>
            <tr>
              <th scope="row">Beard Status</th>
              <td >{{currentUser.beard}}</td>
            </tr>
            <tr>
              <th scope="row">Smoking Habit</th>
              <td >{{currentUser.smoker}}</td>
              </tr>
          </tbody>
        </table>
            </div>

            <div class="col-sm-12" *ngIf=!isMale()>

            <table class="table">
              <tbody>
                <tr>
                  <th scope="row">I Wear a-</th>
                  <td >{{currentUser.wear}}</td>
                </tr>

                <tr>
                <th scope="row">Smoking Habit</th>
                <td >{{currentUser.smoker}}</td>
                </tr>
              </tbody>
            </table>

            </div>

        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="flush-headingFive">
        <button class="btn btn-danger accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">

  SPOUSAL PREFERENCES
        </button>
      </h2>
      <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
        <div class="accordion-body">

            <table class="table">
              <tbody>
                <tr>
                  <th scope="row">Religious Practices</th>
                  <td >{{currentUser.preReligious}}</td>
                </tr>
                <tr>
                  <th scope="row">If Other, Please Specify</th>
                  <td >{{currentUser.preReligiousOther}}</td>
                </tr>
                <tr>
                  <th scope="row">Religious Practice Preferences</th>
                  <td >{{currentUser.preReligiousBrief}}</td>
                </tr>
              </tbody>
            </table>
            <div *ngIf=isMale()>
            <table class="table">
              <tbody>
                <tr>
                  <th scope="row">Preferences for a wife (Wear)-</th>
                  <td >{{currentUser.preWear}}</td>
                </tr>
              </tbody>
            </table>
          </div>

            <div *ngIf=!isMale()>

            <table class="table">
              <tbody>
                <tr>
                  <th scope="row">Preferences for a husband (Beard/No Beard)-</th>
                  <td >{{currentUser.preBeard}}</td>
                </tr>
              </tbody>
            </table>
            </div>

            <table class="table">
              <tbody>
                <tr>
                  <th scope="row">Fine with Smoking</th>
                  <td >{{currentUser.preSmoking}}</td>
                </tr>
                <tr>
                  <th scope="row">Ethnic Background Preferences</th>
                  <td >{{currentUser.preEthnicSpecific}} </td>
                </tr>
                <tr>
                  <th scope="row">Immigration Status Preferences </th>
                  <td >{{currentUser.preImmigrationStatus}}</td>
                </tr>
                <tr>
                  <th scope="row">Marital Status Preferences</th>
                  <td >{{currentUser.preMaritalStatus}}</td>
                </tr>
                <tr>
                  <th scope="row">Children Preferences</th>
                  <td >{{currentUser.preChildren}}</td>
                </tr>
                <tr>
                  <th scope="row">Education Preferences</th>
                  <td >{{currentUser.preEducation}}</td>
                </tr>
                <tr>
                  <th scope="row">Employment Preferences</th>
                  <td >{{currentUser.preEmployment}}</td>
                </tr>
                <tr>
                  <th scope="row">Age Difference Preferences</th>
                  <td >{{currentUser.preAgeGap}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!--div class="accordion-item">
      <h2 class="accordion-header" id="flush-headingSix">
        <button class="btn btn-danger accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseTwo">
          Additional Information
        </button>
      </h2>
      <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
        <div class="accordion-body">
          <h6>Please mention at least 3 personal and/or professional references</h6>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group form-floating">

                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Serial</th>
                      <th scope="col">Name</th>
                      <th scope="col">Relationship</th>
                      <th scope="col">Phone</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>{{currentUser.refName1}}</td>
                      <td>{{currentUser.refRelation1}}</td>
                      <td>{{currentUser.refPhone1}}</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>{{currentUser.refName2}}</td>
                      <td>{{currentUser.refRelation2}}</td>
                      <td>{{currentUser.refPhone2}}</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td>{{currentUser.refName3}}</td>
                      <td>{{currentUser.refRelation3}}</td>
                      <td>{{currentUser.refPhone3}}</td>
                    </tr>
                  </tbody>
                </table>
          </div>
          </div>
          </div>

        </div>
        </div>
        </div-->

        <!--div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingSeven">
            <button class="btn btn-danger accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSix">

      Conclusion
            </button>
          </h2>
          <div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body">
              <h6>Yes. Registration is completely free. But you may donate us if you like our service.</h6>

            </div>
          </div>
        </div-->
        <br>
  </div>
