import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { ProfileComponent } from './profile/profile.component';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { ViewProfileComponent } from './profile/view-profile/view-profile.component';
import { EditProfileComponent } from './profile/edit-profile/edit-profile.component';
import { MessageComponent } from './profile/message/message.component';
import { NotificationComponent } from './profile/notification/notification.component';
import { AdminComponent } from './profile/admin/admin.component';
import { PasswordComponent } from './password/password.component';
import { AdminloginComponent } from './adminlogin/adminlogin.component';
import { DeactivateProfileComponent } from './profile/deactivate-profile/deactivate-profile.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        RouterModule
    ],
    declarations: [
        SignupComponent,
        ProfileComponent,
        LoginComponent,
        ViewProfileComponent,
        EditProfileComponent,
        MessageComponent,
        NotificationComponent,
        AdminComponent,
        PasswordComponent,
        AdminloginComponent,
        DeactivateProfileComponent,
    ]
})
export class ExamplesModule { }
