<p *ngFor="let alert of alerts">
    <ngb-alert [type]="alert.type"  [dismissible]="false">
        <div class="container">
            <ng-container *ngIf="alert.icon">
                <i class="nc-icon {{alert.icon}}"></i>
            </ng-container>

            {{ alert.message }}
            <button type="button" name="button" class="close" (click)="closeAlert(alert)">
                <i class="nc-icon nc-simple-remove"></i>
            </button>
        </div>
    </ngb-alert>
</p>
