<!--==========================
  Call To Action Section
============================-->
<section id="call-to-action" class="wow fadeInUp">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 text-center text-lg-left">
        <h3 class="cta-title">DONATE</h3>
        <p class="cta-text">You may donate our social work. Do you want to donate us?</p>
      </div>

      <div class="col-lg-9 col-sm-12 text-center">
        <div id="payments" style="text-align:center; position:sticky;"></div>
      </div>

      

      <!--div class="col-lg-3 col-sm-12 text-center">
        <a href = "#"><img src="assets/img/clients/amex.png" alt="" style="padding:20px;"></a>
      </div>

      <div class="col-lg-3 col-sm-12 text-center">
        <a href = "#"><img src="assets/img/clients/paypal.png" alt="" style="padding:20px;"></a>
      </div>

      <div class="col-lg-3 col-sm-12 text-center">
        <a href = "#"><img src="assets/img/clients/master-card.png" alt="" style="padding:20px;"></a>
      </div-->

    </div>


  </div>
</section><!-- #call-to-action -->

<!-- Paper Kit page
==================--
<div class="wrapper">
    <div class="page-header section-dark" style="background-image: url('assets/img/antoine-barres.jpg')">
        <div class="filter"></div>
        <div class="content-center">
            <div class="container">
                <div class="title-brand">
                    <div class="angular-logo">
                        <img src="assets/img/angular2-logo.png" alt="">
                    </div>
                    <h1 class="presentation-title">They Got Married</h1>
                    <div class="fog-low">
                        <img src="assets/img/fog-low.png" alt="">
                    </div>
                    <div class="fog-low right">
                        <img src="assets/img/fog-low.png" alt="">
                    </div>
                </div>

                <h2 class="presentation-subtitle text-center">MUNA Matrimonial</h2>
            </div>
        </div>
        <div class="moving-clouds" style="background-image: url('assets/img/clouds.png'); "></div>
        <h6 class=" category-absolute ml-auto mr-auto">Designed and coded by
         <h6 class="category category-absolute">Designed and coded by --

            <a href="https://www.creative-tim.com" target="_blank">
                <img src="assets/img/creative-tim-white-slim2.png" class="creative-tim-logo">
            </a>
        </h6>
    </div>
    <div class="main">
      <app-basicelements></app-basicelements>
      <app-navigation></app-navigation>
      <div class="section">
          <div class="container">
              <div class="row">
                  <div class="col-md-6">
                      <div class="tim-title">
                          <h3>Progress Bar</h3>
                          <br/>
                      </div>
                      <ngb-progressbar type="success" class="mt-0" [value]="25">25</ngb-progressbar><br>
                      <ngb-progressbar type="info" class="mt-0" [value]="50"><span class="h6 mb-0">Copying file <b>2 of 4</b> ...</span></ngb-progressbar><br>
                      <ngb-progressbar type="warning" class="mt-0" [value]="75" [striped]="true" [animated]="true"><i>50%</i></ngb-progressbar><br>
                      <ngb-progressbar type="danger" class="mt-0" [value]="100" [striped]="true">Completed!</ngb-progressbar>

                  </div>
                  <div class="col-md-6">
                      <div class="tim-title">
                          <h3>Pagination</h3>
                          <br/>
                      </div>
                      <ngb-pagination [collectionSize]="70" [(page)]="page" aria-label="Default pagination"></ngb-pagination>
                      <br/>
                      <ngb-pagination [collectionSize]="100" [(page)]="page1" [maxSize]="5" [rotate]="true"></ngb-pagination>
                  </div>
              </div>
              <br/>
              <div class="row">
                  <div class="col-md-6">
                      <div class="tim-title">
                          <h3>Navigation Tabs</h3>
                      </div>

                      <div class="nav-tabs-navigation">
                          <div class="nav-tabs-wrapper">
                              <ngb-tabset [justify]="'center'">
                                  <ngb-tab title="Home">
                                      <ng-template ngbTabContent>
                                          <p>Larger, yet dramatically thinner. More powerful, but remarkably power efficient. With a smooth metal surface that seamlessly meets the new Retina HD display.</p>
                                      </ng-template>
                                  </ngb-tab>
                                  <ngb-tab title="Profile">
                                      <ng-template ngbTabContent>
                                          <p>Here is your profile.</p>
                                      </ng-template>
                                  </ngb-tab>
                                  <ngb-tab title="Messages">
                                      <ng-template ngbTabContent>
                                          <p>Here are your messages.</p>
                                      </ng-template>
                                  </ngb-tab>
                              </ngb-tabset>
                          </div>
                      </div>

                  </div>

                  <div class="col-md-6">
                      <div class="tim-title">
                          <h3>Labels</h3>
                      </div>
                      <span class="label label-default">Default</span>
                      <span class="label label-primary">Primary</span>
                      <span class="label label-info">Info</span>
                      <span class="label label-success">Success</span>
                      <span class="label label-warning">Warning</span>
                      <span class="label label-danger">Danger</span>
                  </div>
              </div>
          </div>
      </div>
      <div class="section section-notifications" id="notifications">
          <div class="container">
              <div class="tim-title">
                  <h3>Notification</h3>
              </div>
          </div>
          <app-notification></app-notification>
      </div>
      <app-typography></app-typography>
      <div class="section javascript-components">
          <div class="container">
              <div class="tim-title">
                  <h2>Angular Native Components</h2>
              </div>
              <div class="row" id="modals">
                  <div class="col-md-6">
                      <div class="tim-title">
                          <h3>Modal</h3>
                      </div>
                      <app-modal-component></app-modal-component>
                  </div>
                  <div class="col-md-6">
                      <div class="tim-title">
                          <h3>Popovers</h3>
                      </div>

                      <button type="button" class="btn btn-outline-danger btn-round" placement="right"
                          ngbPopover="Here will be some very useful information about this popover." popoverTitle="Popover on right">
                          on right
                      </button>

                      <button type="button" class="btn btn-outline-danger btn-round" placement="top"
                          ngbPopover="Here will be some very useful information about this popover." popoverTitle="Popover on top">
                          on top
                      </button>

                      <button type="button" class="btn btn-outline-danger btn-round" placement="left"
                          ngbPopover="Here will be some very useful information about this popover." popoverTitle="Popover on left">
                          on left
                      </button>

                      <button type="button" class="btn btn-outline-danger btn-round" placement="bottom"
                          ngbPopover="Here will be some very useful information about this popover." popoverTitle="Popover on bottom">
                          on bottom
                      </button>

                  </div>
                  <br/>
                  <div class="col-md-6">
                      <div class="tim-title">
                          <h3>Datepicker</h3>
                      </div>
                      <div class="row">
                          <div class='col-sm-6'>
                              <form class="form-inline">
                                  <div class="form-group">
                                      <div class="input-group date" [ngClass]="{'input-group-focus':focus===true}">
                                          <input class="form-control" placeholder="yyyy-mm-dd"
                                               name="dp" [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" (focus)="focus=true" (blur)="focus=false" />
                                          <div class="input-group-append">
                                              <span class="glyphicon glyphicon-calendar input-group-text">
                                                <i class="fa fa-calendar" aria-hidden="true"></i>
                                              </span>
                                          </div>
                                      </div>
                                  </div>
                              </form>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="tim-title">
                          <h3>Tooltips</h3>
                      </div>

                      <button type="button" class="btn btn-outline-danger btn-round" placement="right" ngbTooltip="Tooltip on right">
                         on right
                     </button>
                     <button type="button" class="btn btn-outline-danger btn-round" placement="left" ngbTooltip="Tooltip on left">
                        on left
                     </button>
                      <button type="button" class="btn btn-outline-danger btn-round" placement="top" ngbTooltip="Tooltip on top">
                         on top
                      </button>
                      <button type="button" class="btn btn-outline-danger btn-round" placement="bottom" ngbTooltip="Tooltip on bottom">
                         on bottom
                      </button>
                  </div>
              </div>
          </div>
      </div>
      <div class="section" id="carousel">
          <div class="container">
              <div class="tim-title">
                  <h3>Carousel</h3>
              </div>
              <div class="row">
                  <div class="col-md-8 mr-auto ml-auto">
                      <div class="card page-carousel">
                          <ngb-carousel>
                              <ng-template ngbSlide>
                                  <img src="./assets/img/soroush-karimi.jpg" alt="Random first slide">
                                  <div class="carousel-caption">
                                      <p>Somewhere</p>
                                  </div>
                              </ng-template>
                              <ng-template ngbSlide>
                                  <img src="./assets/img/federico-beccari.jpg" alt="Random second slide">
                                  <div class="carousel-caption">
                                      <p>Somewhere else</p>
                                  </div>
                              </ng-template>
                              <ng-template ngbSlide>
                                  <img src="./assets/img/joshua-stannard.jpg" alt="Random third slide">
                                  <div class="carousel-caption">
                                      <p>Here it is</p>
                                  </div>
                              </ng-template>
                          </ngb-carousel>
                      </div>
                  </div>
              </div>
          </div>
      </div>
        <div class="section section-dark section-nucleo-icons">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <h2 class="title">Nucleo Icons</h2><br/>
                        <p class="description">
                            Now UI Kit comes with 100 custom icons made by our friends from NucleoApp. The official package contains over 2.100 thin icons which are looking great in combination with Now UI Kit Make sure you check all of them and use those that you like the most.
                        </p><br/>
                        <a [routerLink]="['/nucleoicons']" class="btn btn-danger btn-round">View Demo Icons</a>
                        <a href="https://nucleoapp.com/?ref=1712" class="btn btn-outline-danger btn-round" target="_blank">View All Icons</a>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="icons-container">
                            <i class="nc-icon nc-time-alarm"></i>
                            <i class="nc-icon nc-atom"></i>
                            <i class="nc-icon nc-camera-compact"></i>
                            <i class="nc-icon nc-watch-time"></i>
                            <i class="nc-icon nc-key-25"></i>
                            <i class="nc-icon nc-diamond"></i>
                            <i class="nc-icon nc-user-run"></i>
                            <i class="nc-icon nc-layout-11"></i>
                            <i class="nc-icon nc-badge"></i>
                            <i class="nc-icon nc-bulb-63"></i>
                            <i class="nc-icon nc-favourite-28"></i>
                            <i class="nc-icon nc-planet"></i>
                            <i class="nc-icon nc-tie-bow"></i>
                            <i class="nc-icon nc-zoom-split"></i>
                            <i class="nc-icon nc-cloud-download-93"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section section-dark">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h2 class="title">Completed with examples</h2>
                        <p class="description">The kit comes with three pre-built pages to help you get started faster. You can change the text and images and you're good to go. More importantly, looking at them will give you a picture of what you can built with this powerful kit.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="section section-image section-login" style="background-image: url('assets/img/login-image.jpg');">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-12 ml-auto mr-auto">
                        <div class="card card-register">
                            <h3 class="title">Welcome</h3>
                            <div class="social-line text-center">
                                <a href="#pablo" class="btn btn-neutral btn-facebook btn-just-icon">
                                    <i class="fa fa-facebook-square"></i>
                                </a>
                                <a href="#pablo" class="btn btn-neutral btn-google btn-just-icon">
                                    <i class="fa fa-google-plus"></i>
                                </a>
                                <a href="#pablo" class="btn btn-neutral btn-twitter btn-just-icon">
                                    <i class="fa fa-twitter"></i>
                                </a>
                            </div>
                            <form class="register-form">
                                <label>Email</label>
                                <div class="input-group form-group-no-border" [ngClass]="{'input-group-focus':focus===true}">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="nc-icon nc-email-85"></i>
                                    </span>
                                  </div>

                                    <input type="text" class="form-control" placeholder="Email" (focus)="focus=true" (blur)="focus=false" >
                                </div>

                                <label>Password</label>
                                <div class="input-group form-group-no-border" [ngClass]="{'input-group-focus':focus1===true}">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="nc-icon nc-key-25"></i>
                                    </span>
                                  </div>
                                    <input type="text" class="form-control" placeholder="Password" (focus)="focus1=true" (blur)="focus1=false" >
                                </div>
                                <button class="btn btn-danger btn-block btn-round">Register</button>
                            </form>
                            <div class="forgot">
                                <a href="#" class="btn btn-link btn-danger">Forgot password?</a>
                            </div>
                        </div>
                        <div class="col text-center" routerLinkActive="active">
                            <a [routerLink]="['/signup']" class="btn btn-outline-neutral btn-round btn-lg">View Register Page</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section section-dark">
            <div class="container">
                <div class="row example-page">
                    <div class="col-md-6 text-center" routerLinkActive="active">
                        <a [routerLink]="['/landing']">
                            <img src="./assets/img/examples/landing-page.JPG" alt="Rounded Image" class="img-rounded img-responsive" style="width: 100%">
                            <a [routerLink]="['/landing']" class="btn btn-outline-neutral btn-round">Landing Page</a>
                        </a>
                    </div>

                    <div class="col-md-6 text-center" routerLinkActive="active">
                        <a [routerLink]="['/user-profile']">
                            <img src="./assets/img/examples/profile-page.JPG" alt="Rounded Image" class="img-rounded img-responsive" style="width: 100%">
                            <a [routerLink]="['/user-profile']" class="btn btn-outline-neutral btn-round">Profile Page</a>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="container text-center">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h2 class="title">Do you like what you see?</h2>
                        <p class="description">Cause if you do, it can be yours for Free. Hit the button below and download it. Start a new project or give an old Bootstrap 4 project a new look.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 download-area ml-auto mr-auto">
                        <a href="https://www.creative-tim.com/product/paper-kit-2" class="btn btn-warning btn-round">Download free HTML</a>
                        <a href="https://www.creative-tim.com/product/paper-kit-2-angular" class="btn btn-danger btn-round">Download free Angular</a>
                    </div>
                </div>
                <div class="row justify-content-md-center sharing-area text-center">
                    <div class="text-center col-md-12 col-lg-8">
                        <h3>Thank you for supporting us!</h3>
                    </div>
                    <div class="text-center col-md-12 col-lg-8">
                        <a href="#pablo" class="btn btn-twitter-bg twitter-sharrre btn-round" rel="tooltip" title="Tweet!">
                            <i class="fa fa-twitter"></i> Twitter
                        </a>
                        <a href="#pablo" class="btn btn-google-bg linkedin-sharrre btn-round" rel="tooltip" title="Share!">
                            <i class="fa fa-google-plus"></i> Google
                        </a>
                        <a href="#pablo" class="btn btn-facebook-bg facebook-sharrre btn-round" rel="tooltip" title="Share!">
                            <i class="fa fa-facebook-square"></i> Facebook
                        </a>
                        <a href="https://github.com/creativetimofficial/paper-kit" class="btn btn-github-bg btn-github sharrre btn-round" rel="tooltip" title="Star on Github">
                            <i class="fa fa-github"></i> Star
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
-->
