<div class="section">
    <div class="container tim-container">
        <div class="tim-title">
            <h3>Typography</h3>
            <br/>
        </div>

        <br/>
        <div id="images">
            <div class="container">
                <div class="tim-title">
                    <h3>Images</h3>
                </div>
                <div class="row">
                    <div class="col-xs-8 col-sm-6 col-md-3">
                        <h4 class="images-title">Rounded Image</h4>
                        <img src="assets/img/uriel-soberanes.jpg" class="img-rounded img-responsive" alt="Rounded Image">
                        <div class="img-details">
                            <div class="author">
                                <img src="assets/img/faces/clem-onojeghuo-2.jpg" alt="Circle Image" class="img-circle img-no-padding img-responsive">
                            </div>
                            <p>Chet Faker</p>
                        </div>
                    </div>
                    <div class="col-xs-6  col-sm-3 col-md-2 offset-md-1">
                        <h4 class="images-title">Circle Image</h4>
                        <img src="assets/img/faces/joe-gardner-2.jpg" class="img-circle img-no-padding img-responsive" alt="Rounded Image">
                        <p class="text-center">Joe Gardner</p>
                    </div>
                    <div class="col-xs-6  col-sm-3 col-md-2 offset-md-1">
                        <h4 class="images-title">Thumbnail</h4>
                        <img src="assets/img/faces/clem-onojeghuo-3.jpg" class="img-thumbnail img-responsive" alt="Rounded Image">
                        <p class="text-center">Erik Faker</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
