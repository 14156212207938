<div class="wrapper">
    <div class="page-header" style="background-image: url('assets/img/intro-carousel/Picture1.jpg');">
        <div class="filter"></div>

            <div class="container">
                <div class="row center">
                    <div class="col-lg-4 mr-auto ml-auto">
                      <div class="card card-register">
                          <h3 class="title">Change Password</h3>

                          <!-- social text icon
                            =======================--
                          <div class="social-line text-center">
                              <a href="#pablo" class="btn btn-neutral btn-facebook btn-just-icon">
                                  <i class="fa fa-facebook-square"></i>
                              </a>
                              <a href="#pablo" class="btn btn-neutral btn-google btn-just-icon">
                                  <i class="fa fa-google-plus"></i>
                              </a>
                              <a href="#pablo" class="btn btn-neutral btn-twitter btn-just-icon">
                                  <i class="fa fa-twitter"></i>
                              </a>
                          </div> -->
                          <div class="register-form">
                            <div>
                              <label>Old Password/Verification Code</label>
                              <div class="input-group form-group-no-border" [ngClass]="{'input-group-focus':focus1===true}">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">
                                      <i class="nc-icon nc-key-25"></i>
                                  </span>
                                </div>
                                  <input type="password" [(ngModel)]="password" class="form-control" placeholder="Password" 
                                  (keyup.enter)="changePassword()" (focus)="focus1=true" (blur)="focus1=false" >
                              </div>
                              <label>New Password
                                <ng-container style="color:red;" *ngIf="!passwordValidate()"><br>Password should be minimum 8 characters and must contain uppercase, lowercase, number and special characcter! </ng-container>
                              </label>
                              <div class="input-group form-group-no-border" [ngClass]="{'input-group-focus':focus1===true}">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">
                                      <i class="nc-icon nc-key-25"></i>
                                  </span>
                                </div>
                                  <input type="password" [(ngModel)]="pass1" class="form-control" placeholder="Password" 
                                  (keyup.enter)="changePassword()" (focus)="focus1=true" (blur)="focus1=false" >
                              </div>
                              <label>Retype New Password</label>
                              <div class="input-group form-group-no-border" [ngClass]="{'input-group-focus':focus1===true}">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">
                                      <i *ngIf=!passMatched() class="nc-icon nc-alert-circle-i"></i>
                                      <i *ngIf=passMatched() style="color:green;" class="nc-icon nc-check-2"></i>
                                  </span>
                                </div>
                                  <input type="password" [(ngModel)]="pass2" class="form-control" placeholder="Password" 
                                  (keyup.enter)="changePassword()" (focus)="focus1=true" (blur)="focus1=false" >
                              </div>

                              <button (click)="changePassword()" class="btn btn-danger btn-block btn-round">Confirm Change</button>
                              <p style="text-align:center; font-size:bold; color:red;">{{errorMessage}}</p>
                            </div>
                          </div>
                      </div>
                    </div>
                </div>
                <div class="footer register-footer text-center">
                    <h6>&copy;{{test | date: 'yyyy'}}, made with <i class="fa fa-heart heart"></i> by Innovation21</h6>
                </div>
            </div>
    </div>
</div>
