<!--==========================
  About Section
============================-->
<section id="about" class="wow fadeInUp">
  <div class="container">
  <div class="section-header">
      <h2>AIMS AND OBJECTIVES</h2>
      <br>
      <p>{{aboutHeading}}</p>
      </div>
    <div class="row">
      <div class="col-lg-6 about-img">
        <img src="{{PhotoUrl+about.postPhoto}}" alt="" style="height: {{about.param1}}px;">
      </div>

      <div class="col-lg-6 content">
        <h2 style="pad-top:0px;">{{about.param2}}</h2>
        <h3>{{about.param3}}</h3>
        <br>
        <p><b>{{about.param4}}</b></p>
        <ul>
          <li *ngFor="let program of programs">
            <ng-container  *ngIf=program!=''>
            <i class="icon ion-ios-checkmark-outline"></i>{{program}}
            </ng-container></li>
          </ul>
      </div>
    </div>

  </div>


</section><!-- #about -->


<!--==========================
  Our Team Section
============================-->
<section id="team" class="wow fadeInUp">

  <div class="container">
    <div class="section-header">
      <h2>Our Team</h2>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 ml-auto mr-auto" *ngFor="let mb of team">
        <div class="member">
          <!--div class="pic"><img src="assets/img/team1.jpg" alt=""></div-->
          <div class="details">
            <h4>{{mb.param1}}</h4>
            <h6></h6>
            <h6>{{mb.param2}}</h6>
            <span>{{mb.param3}}</span>
            <!--div class="social">
              <a href="{{mb.param4.split('\n')[0]}}" target="_blank"><i class="fa fa-twitter"></i></a>
              <a href="{{mb.param4.split('\n')[1]}}" target="_blank"><i class="fa fa-facebook"></i></a>
              <a href="{{mb.param4.split('\n')[2]}}" target="_blank"><i class="fa fa-google-plus"></i></a>
              <a href="{{mb.param4.split('\n')[3]}}" target="_blank"><i class="fa fa-linkedin"></i></a>
            </div-->
            <br>
          </div>
        </div>
      </div>
      </div>


  </div>
</section><!-- #team -->
