<footer  id="footer">
    <div class="container">
        
            <nav class="nav">
                <ul>
                    <li><a [routerLink]="['/abouts']" style="color:#777777;">About</a></li>
                    <li><a [routerLink]="['/privacy']" style="color:#777777;">Privacy Policy</a></li>
                    <li><a [routerLink]="['/faqs']" style="color:#777777;">FAQ</a></li>
                    <li  *ngFor="let link of links"><a href="{{link.param2}}" target="_blank" style="color:#777777;">{{link.param1}}</a></li>
                </ul>
            </nav>
            <div class="credits ml-auto">
                <span class="copyright">
                    Copyright © {{test | date: 'yyyy'}} MUNA | All Rights Reserved
                </span><br>
                <span class="credits"> 
                  Template by <a href="https://webthemez.com/consulting/">WebThemez</a>
                </span>
            </div>
            
            
        
    </div>
</footer>

<!--==========================
  Footer
============================--
<footer id="footer">
  <div class="container">
    <nav class="footer-nav">
        <ul>
            <li><a href="#" style="color:#777777;">About</a></li>
            <li><a href="#" style="color:#777777;">Privacy Policy</a></li>
            <li><a href="#" style="color:#777777;">FAQ</a></li>
            <li><a href="#" style="color:#777777;">Important Links</a></li>
        </ul>
    </nav>
    <div class="copyright">
      &copy; Copyright <strong>Innovation 21</strong>. All Rights Reserved
    </div>
    <div class="credits">
      Template by <a href="https://webthemez.com/consulting/">WebThemez</a>
    </div>
  </div>
</footer><!-- #footer -->

<a href="#" class="back-to-top"><i class="fa fa-chevron-up"></i></a>
