
<div class="modal fade" id="bannerModal" tabindex="-1"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog modal-lg modal-dialog-centered">
<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{modalTitle}}</h5>
        <button type="button" class="btn-close float-end" data-dismiss="modal"
        aria-label="Close"></button>
    </div>
    <div class="modal-body">
    <div class="d-flex flex-row bd-highlight">
        <div class="p-4 w-100 bd-highlight">
          <div class="form-group form-floating">
              <input type="text" [(ngModel)]="webtitle" class="form-control" placeholder="Enter Your Full Name" id="fullName">
              <label for="FullName">Website Title</label>
          </div>

          <div class="form-group form-floating">
              <input type="text" [(ngModel)]="webslogan" class="form-control" placeholder="Cell Phone Number" id="cellPhone">
              <label for="CellPhone">Website Slogan</label>
          </div>

        </div>
    </div>

        <button type="button"
        class="btn btn-primary float-end" data-dismiss="modal">
        Update Banner
        </button>

    </div>


</div>
</div>
</div>

<div class="modal fade" id="sliderModal" tabindex="-1"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog modal-lg modal-dialog-centered">
<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Update Slider</h5>
        <button type="button" class="btn-close float-end" data-dismiss="modal"
        aria-label="Close"></button>
    </div>
    <div class="modal-body">
    <div class="d-flex flex-row bd-highlight">
        <div class="row">
          <div class="col-md-6" *ngFor="let slider of sliders">
            <img [src]="PhotoUrl+slider.postPhoto" class="img-rounded img-padding img-responsive"
            style="width:100%; height:200px;">
            <div class="row form-group form-floating">
                <input type="file" title="image" (change)="uploadPhoto($event,slider)" class="mt-2 col-md-8"/>
                <button type="button" name="button" style="height:30px;" class="col-md-3" (click)=deleteSlider(slider)>Delete</button>
            </div>

            <div class="form-group form-floating">
                <input type="text" [(ngModel)]=slider.postDetails class="form-control" placeholder="Enter Your Full Name" id="fullName">
                <label for="FullName">Caption</label>
            </div>
          </div>

          <div class="col-md-6">
            <img src="assets/img/intro-carousel/791768-middle.png" class="img-rounded img-padding img-responsive"
            style="width:100%; height:200px;">
            <div class="form-group form-floating">
                <input type="file" title="image" (change)="uploadPhoto($event,0)" class="mt-2"/>
            </div>
          </div>

        </div>
    </div>

        <button type="button" (click)="updateSlider()"
        class="btn btn-primary float-end" data-dismiss="modal">
        Update Slider
        </button>

    </div>


</div>
</div>
</div>


<!--==========================
  Intro Section
============================-->
<section id="intro">
  <div class="intro-content">
    <h2><span>{{webtitle}}</span><br>{{webslogan}}
      <button *ngIf=false type="button" class="btn btn-light mr-1"
      data-toggle="modal" data-target="#bannerModal"
      (click)="editClick('Banner')"
      data-backdrop="static" data-keyboard="false">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
          <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
        </svg>
      </button>
      </h2>
      <!--button *ngIf="isAdmin()" type="button" class="btn btn-light mr-1"
      data-toggle="modal" data-target="#sliderModal"
      data-backdrop="static" data-keyboard="false">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
          <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
        </svg>
      Update Slider</button-->


    <!--<div>
      <a href="#contact" class="btn-get-started scrollto">Contact Us</a>
    </div> -->

        <div  *ngIf="!loggedin" class="mr-auto ml-auto">

          <div class="card-register">

              <form class="register-form">
                <div>

                    <label>I am looking for a</label>
                    <div class="input-group form-group-no-border" [ngClass]="{'input-group-focus':focus===true}">
                      <div class="input-group-prepend">

                      </div>

                        <select ng-model="myVar" class="form-control"  >
                          <option selected value="female">Female
                          <option value="male">Male
                        </select>
                    </div>


                </div>

                <label>Age Range</label>

                <div class="input-group form-group-no-border" [ngClass]="{'input-group-focus':focus===true}">


                    <input type="text" class="form-control" placeholder="20" (focus)="focus=true" (blur)="focus=false" style="text-align:center;" >
                    <input type="text" class="form-control" value="to"  style="text-align:center;" disabled>
                    <input type="text" class="form-control" placeholder="25" (focus)="focus=true" (blur)="focus=false" style="text-align:center;" >
                </div>
                  <!--
                  <label>Email</label>

                  <div class="input-group form-group-no-border" [ngClass]="{'input-group-focus':focus===true}">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                          <i class="nc-icon nc-email-85"></i>
                      </span>
                    </div>

                      <input type="text" class="form-control" placeholder="Email" (focus)="focus=true" (blur)="focus=false" >
                  </div>
                  -->

                  <a [routerLink]="['/signup']"><button class="btn btn-danger btn-block btn-round">Let's Begin</button></a>
                  <!--temporary for convention->
                    <a [routerLink]="['/create-profile']"><button class="btn btn-danger btn-block btn-round">Let's Begin</button></a>
                  <!---->
              </form>
          </div>
        </div>
  </div>
   <div id="intro-carousel" class="owl-carousel" >
    <div class="item" style="background-image: url('{{PhotoUrl}}{{psliders[0].postPhoto}}');">
      <div class="caption">
          <p>{{psliders[0].param1}}</p>
      </div>
    </div>
    <div class="item" style="background-image: url('{{PhotoUrl}}{{psliders[1].postPhoto}}');">
      <div class="caption">
          <p>{{psliders[1].param1}}</p>
      </div>
    </div>

    <div class="item" style="background-image: url('{{PhotoUrl}}{{psliders[2].postPhoto}}');">
      <div class="caption">
          <p>{{psliders[2].param1}}</p>
      </div>
    </div>

    <div class="item" style="background-image: url('{{PhotoUrl}}{{psliders[3].postPhoto}}');">
      <div class="caption">
          <p>{{psliders[3].param1}}</p>
      </div>
    </div>
    <div class="item" style="background-image: url('{{PhotoUrl}}{{psliders[4].postPhoto}}');">
      <div class="caption">
          <p>{{psliders[4].param1}}</p>
      </div>
    </div>

    <div class="item" style="background-image: url('{{PhotoUrl}}{{psliders[5].postPhoto}}');">
      <div class="caption">
          <p>{{psliders[5].param1}}</p>
      </div>
    </div>
    <div class="item" style="background-image: url('{{PhotoUrl}}{{psliders[6].postPhoto}}');">
      <div class="caption">
          <p>{{psliders[6].param1}}</p>
      </div>
    </div>

    <div class="item" style="background-image: url('{{PhotoUrl}}{{psliders[7].postPhoto}}');">
      <div class="caption">
          <p>{{psliders[7].param1}}</p>
      </div>
    </div>
    <div class="item" style="background-image: url('{{PhotoUrl}}{{psliders[8].postPhoto}}');">
      <div class="caption">
          <p>{{psliders[8].param1}}</p>
      </div>
    </div>


  </div>
</section><!-- #intro -->





<!-- housing main
===========================-->
<main id="main">
  <app-services></app-services>
  <app-events></app-events>
  <app-about></app-about>
  <app-faq></app-faq>
  <app-donate></app-donate>
  <app-contact></app-contact>
</main><!-- housing -->





  <!--==========================
    Clients Section
  ============================--
  <section id="clients" class="wow fadeInUp">
    <div class="container">
      <div class="section-header">
        <h2>Clients</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolores quae porro consequatur aliquam, incidunt fugiat culpa esse aute nulla. duis fugiat culpa esse aute nulla ipsum velit export irure minim illum fore</p>
      </div>

      <div class="owl-carousel clients-carousel">
        <img src="../assets/img/clients/client-1.png" alt="">
        <img src="../assets/img/clients/client-2.png" alt="">
        <img src="../assets/img/clients/client-3.png" alt="">
        <img src="../assets/img/clients/client-4.png" alt="">
        <img src="../assets/img/clients/client-5.png" alt="">
        <img src="../assets/img/clients/client-6.png" alt="">
      </div>

    </div>
  </section><!-- #clients -->






  <!--==========================
    Our Portfolio Section
  ============================--
  <section id="portfolio" class="wow fadeInUp">
    <div class="container">
      <div class="section-header">
        <h2>Our Portfolio</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolores quae porro consequatur aliquam, incidunt fugiat culpa esse aute nulla. nulla duis fugiat culpa esse aute nulla ipsum velit export irure minim illum fore</p>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row no-gutters">

        <div class="col-lg-3 col-md-4">
          <div class="portfolio-item wow fadeInUp">
            <a href="../assets/img/portfolio/1.jpg" class="portfolio-popup">
              <img src="../assets/img/portfolio/1.jpg" alt="">
              <div class="portfolio-overlay">
                <div class="portfolio-info"><h2 class="wow fadeInUp">Portfolio Name</h2></div>
              </div>
            </a>
          </div>
        </div>

        <div class="col-lg-3 col-md-4">
          <div class="portfolio-item wow fadeInUp">
            <a href="../assets/img/portfolio/2.jpg" class="portfolio-popup">
              <img src="../assets/img/portfolio/2.jpg" alt="">
              <div class="portfolio-overlay">
                <div class="portfolio-info"><h2 class="wow fadeInUp">Portfolio Name</h2></div>
              </div>
            </a>
          </div>
        </div>

        <div class="col-lg-3 col-md-4">
          <div class="portfolio-item wow fadeInUp">
            <a href="../assets/img/portfolio/3.jpg" class="portfolio-popup">
              <img src="../assets/img/portfolio/3.jpg" alt="">
              <div class="portfolio-overlay">
                <div class="portfolio-info"><h2 class="wow fadeInUp">Portfolio Name</h2></div>
              </div>
            </a>
          </div>
        </div>

        <div class="col-lg-3 col-md-4">
          <div class="portfolio-item wow fadeInUp">
            <a href="../assets/img/portfolio/4.jpg" class="portfolio-popup">
              <img src="../assets/img/portfolio/4.jpg" alt="">
              <div class="portfolio-overlay">
                <div class="portfolio-info"><h2 class="wow fadeInUp">Portfolio Name</h2></div>
              </div>
            </a>
          </div>
        </div>

        <div class="col-lg-3 col-md-4">
          <div class="portfolio-item wow fadeInUp">
            <a href="../assets/img/portfolio/5.jpg" class="portfolio-popup">
              <img src="../assets/img/portfolio/5.jpg" alt="">
              <div class="portfolio-overlay">
                <div class="portfolio-info"><h2 class="wow fadeInUp">Portfolio Name</h2></div>
              </div>
            </a>
          </div>
        </div>

        <div class="col-lg-3 col-md-4">
          <div class="portfolio-item wow fadeInUp">
            <a href="../assets/img/portfolio/6.jpg" class="portfolio-popup">
              <img src="../assets/img/portfolio/6.jpg" alt="">
              <div class="portfolio-overlay">
                <div class="portfolio-info"><h2 class="wow fadeInUp">Portfolio Name </h2></div>
              </div>
            </a>
          </div>
        </div>

        <div class="col-lg-3 col-md-4">
          <div class="portfolio-item wow fadeInUp">
            <a href="../assets/img/portfolio/7.jpg" class="portfolio-popup">
              <img src="../assets/img/portfolio/7.jpg" alt="">
              <div class="portfolio-overlay">
                <div class="portfolio-info"><h2 class="wow fadeInUp">Portfolio Name </h2></div>
              </div>
            </a>
          </div>
        </div>

        <div class="col-lg-3 col-md-4">
          <div class="portfolio-item wow fadeInUp">
            <a href="../assets/img/portfolio/8.jpg" class="portfolio-popup">
              <img src="../assets/img/portfolio/8.jpg" alt="">
              <div class="portfolio-overlay">
                <div class="portfolio-info"><h2 class="wow fadeInUp">Portfolio Name</h2></div>
              </div>
            </a>
          </div>
        </div>

      </div>

    </div>
  </section><!-- #portfolio -->

  <!--==========================
    Testimonials Section
  ============================--
  <section id="testimonials" class="wow fadeInUp">
    <div class="container">
      <div class="section-header">
        <h2>Testimonials</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolores quae porro consequatur aliquam, incidunt fugiat culpa esse aute nulla. duis fugiat culpa esse aute nulla ipsum velit export irure minim illum fore</p>
      </div>
      <div class="owl-carousel testimonials-carousel">

          <div class="testimonial-item">
            <p>Dolores quae porro consequatur aliquam, incidunt fugiat culpa esse aute nulla. duis fugiat culpa esse aute nulla ipsum velit export irure minim illum fore

            </p>
            <h3>Mrio James</h3>
            <h4>CEO &amp; Founder</h4>
          </div>

          <div class="testimonial-item">
            <p>
              Dolores quae porro consequatur aliquam, incidunt fugiat culpa esse aute nulla. duis fugiat culpa esse aute nulla ipsum velit export irure minim illum fore

            </p>
            <h3>Finton Gofnes</h3>
            <h4>CTO</h4>
          </div>

          <div class="testimonial-item">
            <p>
              Dolores quae porro consequatur aliquam, incidunt fugiat culpa esse aute nulla. duis fugiat culpa esse aute nulla ipsum velit export irure minim illum fore

            </p>
            <h3>Marcus Kell</h3>
            <h4>Marketing</h4>
          </div>

          <div class="testimonial-item">
            <p>
              Dolores quae porro consequatur aliquam, incidunt fugiat culpa esse aute nulla. duis fugiat culpa esse aute nulla ipsum velit export irure minim illum fore

            </p>
            <h3>Williams Belly</h3>
            <h4>Accounts</h4>
          </div>

          <div class="testimonial-item">
            <p>
              Dolores quae porro consequatur aliquam, incidunt fugiat culpa esse aute nulla. duis fugiat culpa esse aute nulla ipsum velit export irure minim illum fore

            </p>
            <h3>Larry Hanson</h3>
            <h4>Investor</h4>
          </div>

      </div>

    </div>
  </section><!-- #testimonials -->

  <!--==========================
    Pricing Section
  ============================--

 <section id="pricing" class="pricing-table">

       <div class="container">
      <div class="section-header">
        <h2>Pricing Table</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolores quae porro consequatur aliquam, incidunt fugiat culpa esse aute nulla. nulla duis fugiat culpa esse aute nulla ipsum velit export irure minim illum fore</p>
      </div>
    </div>
   <div class="container">
          <div class="row justify-content-md-center">
              <div class="col-md-5 col-lg-4">
                  <div class="item">
                      <div class="heading">
                          <h3>RESIDENTIAL</h3>
                      </div>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                      <div class="features">
                        <ul class="list">
                          <li><span>Interior Design</span></li>
                          <li>Project Discussion</li>
                          <li>Space Planning</li>
                          <li>Online Consultation</li>
                        </ul>
                      </div>
                      <div class="price">
                          <h4>$25</h4>
                      </div>
                      <button class="btn btn-block btn-outline-primary" type="submit">BUY NOW</button>
                  </div>
              </div>
              <div class="col-md-5 col-lg-4">
                  <div class="item">
                      <div class="ribbon">Best</div>
                      <div class="heading">
                          <h3>COMMERCIAL</h3>
                      </div>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                      <div class="features">
                        <ul class="list">
                          <li><span>Interior Design</span></li>
                          <li>Project Discussion</li>
                          <li>Space Planning</li>
                          <li>Online Consultation</li>
                        </ul>
                      </div>
                      <div class="price">
                          <h4>$50</h4>
                      </div>
                      <button class="btn btn-block btn-primary" type="submit">BUY NOW</button>
                  </div>
              </div>
              <div class="col-md-5 col-lg-4">
                  <div class="item">
                      <div class="heading">
                          <h3>PROFESSIONAL</h3>
                      </div>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                      <div class="features">
                         <ul class="list">
                          <li><span>Interior Design</span></li>
                          <li>Project Discussion</li>
                          <li>Space Planning</li>
                          <li>Online Consultation</li>
                        </ul>
                      </div>
                      <div class="price">
                          <h4>$150</h4>
                      </div>
                      <button class="btn btn-block btn-outline-primary" type="submit">BUY NOW</button>
                  </div>
              </div>
              </div>
          </div>
  </section>




  <!--==========================
    Call To Action Section
  ============================--
  <section id="call-to-action" class="wow fadeInUp">
    <div class="container">
      <div class="row">
        <div class="col-lg-9 text-center text-lg-left">
          <h3 class="cta-title">Get Our Service</h3>
          <p class="cta-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolores quae porro consequatur aliquam, incidunt fugiat culpa esse aute nulla cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
        </div>
        <div class="col-lg-3 cta-btn-container text-center">
          <a class="cta-btn align-middle" href="#contact">Contact Us</a>
        </div>
      </div>

    </div>
  </section><!-- #call-to-action -->
