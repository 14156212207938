
<!--==========================
  Contact Section
============================-->
<section id="contact" class="wow fadeInUp">
  <div class="container">
    <div class="section-header">
      <h2>Contact Us</h2>
      <br>
      <p>{{contactHeading}}</p>
      </div>

    <div class="row contact-info">
     <div class="col-lg-5">
        <div class="contact-address">
          <i class="ion-ios-location-outline"></i>
          <h3>Address</h3>
          <address>{{contact.param1}}</address>
        </div>
        <div class="contact-phone">
          <i class="ion-ios-telephone-outline"></i>
          <h3>Phone Number</h3>
          <p><a href="tel:{{contact.param2}}"  target="_blank">{{contact.param2}}</a></p>
        </div>
        <div class="contact-email">
          <i class="ion-ios-email-outline"></i>
          <h3>Email</h3>
          <p><a href="mailto:{{contact.param3}}" target="_blank">{{contact.param3}}</a></p>
        </div>
        <div class="contact-email">
          <i class="ion-ios-chatbubble-outline"></i>
          <h3>Facebook</h3>
          <p><a href="{{contact.param4.split('\n')[0]}}" target="_blank">{{contact.param4.split('\n')[0].substring(8)}}</a></p>
        </div>
        <div class="contact-email">
          <i class="ion-ios-people-outline"></i>
          <h3>Twitter</h3>
          <p><a href="{{contact.param4.split('\n')[1]}}" target="_blank">{{contact.param4.split('\n')[1].substring(8)}}</a></p>
        </div>
 </div>
 <div class="col-lg-7">
      <div class="container">
    <div class="form">

   <!-- Form itself -->

 <div class="control-group">
               <div class="form-group">
  <input type="text" class="form-control" [(ngModel)]="Name"
         placeholder="Full Name" id="name" required (keyup.enter)="sendMessage()"
             data-validation-required-message="Please enter your name" />
    <p class="help-block"></p>
    <span class="errortext" id="enterName" *ngIf="Name==''">Please enter your Name</span>
    <span class="errortext" *ngIf=!validateName(Name)>Spell your name correctly</span>


   </div>
       </div>
            <div class="form-group">
              <div class="controls">
  <input type="email" class="form-control" placeholder="Email"
              [(ngModel)]="Email"  id="email" required (keyup.enter)="sendMessage()"
           data-validation-required-message="Please enter your email" />
</div>
<span class="errortext" *ngIf=!validateEmail(Email)>Enter Valid Email Address</span>
<span class="errortext" *ngIf="Email==''">Please enter Email Address</span>
  </div>

           <div class="form-group">
             <div class="controls">
     <textarea rows="8" cols="100" class="form-control" [(ngModel)]="Message"
                   placeholder="Message" id="message" required (keyup.enter)="sendMessage()"
       data-validation-required-message="Please enter your message" minlength="5"
                   data-validation-minlength-message="Min 5 characters"
                    maxlength="999" style="resize:none"></textarea>
      <span class="errortext" *ngIf="Message==''">You cannot send a blank Message!</span>
</div>
           </div>

  <div class=" row form-group">
    <div class="col-lg-4">
      <img src="{{PhotoUrl+'imcap/'+CAPTCHA[this.cid]}}" alt="CAPTCHA" height="40">
    </div>
    <div class="col-lg-8">
       <input type="text" class="form-control" [(ngModel)]="Captcha" 
       placeholder="Type Captcha" id="captcha" required (keyup.enter)="sendMessage()"
       data-validation-required-message="Please enter captcha" />
       <p class="help-block"></p>
     </div>
   </div>
   <div id="success"> </div> <!-- For success/fail messages -->
  <button type="submit" class="btn btn-primary pull-right" (click)=sendMessage()>Send</button><br />

    </div>

  </div>
 </div>


    </div>
  </div>

  <div class="container mb-4 map">
<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d387191.33750346623!2d-73.979681!3d40.6974881!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew+York%2C+NY%2C+USA!5e0!3m2!1sen!2sin!4v1541477355474" width="100%" height="350" frameborder="0" style="border:0" allowfullscreen></iframe>
  </div>

</section><!-- #contact -->
