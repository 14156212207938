<!--==========================
  Call To Action Section
============================--
<section id="call-to-action" class="wow fadeInUp">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 text-center mr-auto ml-auto">
        <h3 class="cta-title">SIGN UP FOR FREE!</h3>
      </div>

    </div>

  </div>
</section><!-- #call-to-action -->

<div class="container-fluid" style="height:50px; background-color: #F44336;"></div>

<div class="section landing-section">
    <div class="container">
        <div class="row">
            <div class="col-md-8 mr-auto ml-auto">
                <h2 class="text-center">Sign Up for FREE!</h2>
                <div class="contact-form">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Name</label><span *ngIf=!fullNameValidate() style="color:red;">. *Please insert your name correctly</span>
                            <div class="input-group" [ngClass]="{'input-group-focus':focus===true}">
                                <div class="input-group-prepend">
                                  <span class="input-group-text"><i class="nc-icon nc-single-02"></i></span>
                                </div>
                                <input type="text" class="form-control" [(ngModel)]="FullName" placeholder="Your Full Name" 
                                (keyup.enter)="enterPress()" (focus)="focus=true" (blur)="focus=false" required>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label>Email</label>
                            <span *ngIf=!emailValidate() style="color:red;">. *Please insert valid email address.</span>

                            <div class="input-group" [ngClass]="{'input-group-focus':focus1===true}">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">  <i class="nc-icon nc-email-85"></i></span>
                                </div>
                                <input type="text" class="form-control" [(ngModel)]="Email" placeholder="Email" maxlength="100"
                                (keyup.enter)="enterPress()" (focus)="focus1=true" (blur)="focus1=false" >
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <label>Gender</label>
                            <span *ngIf=!genderValidate() style="color:red;">. *Please select gender</span>
                            <div class="form-group" [ngClass]="{'input-group-focus':focus===true}">

                                <select [(ngModel)]="Gender" class="form-select" placeholder="Select Your Gender"
                                (keyup.enter)="enterPress()">
                                  <option value="Female" selected>Female
                                  <option value="Male">Male
                                </select>
                            </div>
                        </div>
                        <!-- Date of Birth Omitted
                        Instead Birth Year incorporated Below
                        ================--
                        <div class="col-md-6">
                            <label>Date of Birth</label>
                            <div class="input-group date" [ngClass]="{'input-group-focus':focus===true}">
                                <input class="form-control" placeholder="yyyy-mm-dd"
                                name="dp" [(ngModel)]="DateOfBirth" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" (focus)="focus=true" (blur)="focus=false" >
                                <div class="input-group-append">
                                    <span class="glyphicon glyphicon-calendar input-group-text">
                                      <i class="fa fa-calendar" aria-hidden="true"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!-- End date of birth
                        =================-->
                        <div class="col-md-6">
                            <label>Birth Year</label>
                            <span *ngIf=!birthYearValidate() style="color:red;">. *Please select Birth Year</span>
                            <div class="form-group" [ngClass]="{'input-group-focus':focus===true}">
                              <select [(ngModel)]="BirthYear" class="form-select" id="BirthYear" (keyup.enter)="enterPress()">
                                <option *ngFor="let year of YEARS" value={{year}}>{{year}}</option>
                              </select>

                            </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                            <label>Cell Phone</label>
                            <span *ngIf=!telephoneCheck() style="color:red;">. *Please insert valid Phone Number.</span>
                            <div class="input-group" [ngClass]="{'input-group-focus':focus1===true}">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">  <i class="nc-icon nc-mobile"></i></span>
                                </div>
                                <input type="text" class="form-control" [(ngModel)]="CellPhone" placeholder="Cell Phone" 
                                (keyup.enter)="enterPress()" (focus)="focus1=true" (blur)="focus1=false" >
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label>State</label>
                            <span *ngIf=!stateValidate() style="color:red;">. *Please select State</span>
                            <div class="form-group" [ngClass]="{'input-group-focus':focus===true}">
                              <select [(ngModel)]="State" class="form-select" id="state" (keyup.enter)="enterPress()">
                                <option *ngFor="let state of STATES" value={{state.State}}>{{state.State}}</option>
                              </select>

                            </div>
                        </div>


                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <label>Password</label><span *ngIf=!passwordValidate() style="color:red;">. *Enter a valid password
                              <i style="color:yellowgreen;" class="fa fa-question-circle" aria-hidden="true"
                              placement="top" ngbTooltip="Password should be minimum 8 characters and must contain at least one uppercase letter, one lowercase letter, a number, and a special character."></i>
                              </span>
                            <div class="input-group" [ngClass]="{'input-group-focus':focus===true}">
                                <div class="input-group-prepend">
                                  <span class="input-group-text"><i class="nc-icon nc-key-25"></i></span>
                                </div>
                                <input type="password" class="form-control" [(ngModel)]="pass1" placeholder="Set a Password" 
                                (keyup.enter)="enterPress()" (focus)="focus=true" (blur)="focus=false">
                            </div>
                            <label *ngIf=!passwordValidate() style="color: crimson;">Password should be minimum 8 characters and must contain at least one uppercase letter, one lowercase letter, a number, and a special character.</label>
                        </div>
                        <div class="col-md-6">
                            <label>Retype Password .</label>
                            <span >
                              <i *ngIf=!passMatched() style="color:red;" class="fa fa-exclamation-circle" aria-hidden="true"></i>
                              <i *ngIf=passMatched() style="color:green;"class="fa fa-check-circle" aria-hidden="true"></i>
                              <span *ngIf=!passMatched() style="color:red;">. Retype password correctly.</span>
                            </span>
                            <div class="input-group" [ngClass]="{'input-group-focus':focus1===true}">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">  <i class="nc-icon nc-key-25"></i></span>
                                </div>
                                <input type="password" class="form-control" [(ngModel)]="pass2" placeholder="Retype Your Password" 
                                (keyup.enter)="enterPress()" (focus)="focus1=true" (blur)="focus1=false" >
                            </div>
                        </div>

                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-12 ml-auto mr-auto">
                          <div class="form-check">
                            <label class="form-check-label">
                              <input class="form-check-input" type="checkbox" [(ngModel)]="agreed" (click)=agreePrivacy()
                              (mouseover.enter)="enterPress()" checked="">
                              I agree to the PRIVACY POLICY of MUNA matrimonial.
                              <span class="form-check-sign"></span>
                            </label>
                          </div>
                          <a href='' data-bs-toggle="modal" data-bs-target="#policyModal">Click here</a> to view the PRIVACY POLICY.
                        </div>


                    </div>

                    <!-- big message box
                    -------------------
                    <label>Message</label>
                    <textarea class="form-control" rows="4" placeholder="Tell us your thoughts and feelings..."></textarea>
                    <!-- big message box end
                    -------------------->


                    <div class="row">
                        <div class="col-md-4 mr-auto ml-auto">
                          <button *ngIf="!agreed" disabled class="btn btn-danger btn-lg btn-fill">Confirm Registration</button>
                          <button autofocus *ngIf="agreed" (click)="createClick()" class="btn btn-danger btn-lg btn-fill">Confirm Registration</button>
                            <p *ngIf=userAlreadyExist style="color:red; text-align:center;">You have already registered!</p>
                            <!-- <p>{{YEARS[1]}}</p> -->
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5 mr-auto ml-auto" >
                            <a [routerLink]="['/login']" class="btn btn-link" style="color:#F44336;">Already Registered? Click to Login!</a>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="policyModal" tabindex="-1"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog modal-lg modal-dialog-centered">
<div class="modal-content">
    <div class="modal-header">
      <h4 class="title"><a>MUNA Matrimonial Privacy Policy</a></h4>
        <!--button type="button" class="btn-close float-end" data-bs-dismiss="modal"
        aria-label="Close"></button-->
    </div>
    <div class="modal-body">
    <div class="d-flex flex-row bd-highlight">
        <div class="p-4 w-100 bd-highlight">
          <p *ngFor="let para of policy">{{para}}<br></p>

        </div>
    </div>

        <button type="button" class="btn btn-danger float-end"
        data-bs-dismiss="modal">
        OK
        </button>

    </div>

</div>
</div>
</div>
